<template>
  <div>
    <!-- <ScannerInputForm v-if="!isMobile" :reservedTools="myToolsTable.data" :checkReserved="true" :inverseCheck="false" :onSubmit="unreserve"></ScannerInputForm> -->
    <div class="row">
      <div class="col-12">
        <card :title="cardTitle">
          <template v-slot:header-extra v-if="isMobile">
            <div class="unreserve-tools">
              <QrScanner class="qr-scanner-unreserve" @decodedReserve="selectProject" @decodedReturn="unreserve" />
            </div>
          </template>
          <modal :show.sync="showProjectPickModal">
            <card>
              <h4 class="card-title">{{$t('tools.pickProject')}}</h4>

              <div class="col-md-12">
                  <div>
                    <div
                      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                    >
                      <base-input>
                        <el-input
                          class="mb-3 search-input"
                          clearable
                          prefix-icon="el-icon-search"
                          :placeholder="$t('common.searchRecords')"
                          v-model="searchQuery"
                          aria-controls="datatables"
                          @keyup.enter.native="searchProjects"
                          @clear="handleClearSearchProjects"
                        >
                        </el-input>
                      </base-input>

                    </div>

                  <!-- Mobile Table -->
                  <el-table :data="projects" >
                    <el-table-column :label="$t('common.info')" min-width="150">
                      <template slot-scope="scope">
                        <div>
                          <div><strong>{{$t('projects.key')}}:</strong> {{ scope.row.key }}</div>
                          <div><strong>{{$t('common.name')}}:</strong> {{ scope.row.name }}</div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column  width="50">
                      <template slot-scope="scope" >
                        <el-radio  v-model="selectedProject" :label="scope.row.id"></el-radio>
                      </template>
                    </el-table-column>
                  </el-table>

                  </div>
                  <div
                    slot="footer"
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <div class="">
                      <p class="card-category">
                        {{ $t('pagination.showing') }} {{ projectsFrom + 1 }} {{ $t('pagination.to') }} {{ projectsTo }} {{ $t('pagination.of') }} {{ projectsTotal }} {{ $t('pagination.entries') }}
                      </p>
                    </div>
                    <base-pagination
                      @input="projectsUpdatePage"
                      class="pagination-no-border"
                      v-model="projectsPagination.currentPage"
                      :per-page="projectsPagination.perPage"
                      :total="projectsTotal"
                    >
                    </base-pagination>
                  </div>
                  <!-- button -->
                <div class="row">
                  <div class="col-sm d-flex justify-content-end">
                      <base-button @click="reserveTool" type="standard" fill>{{$t('common.confirm')}}</base-button>
                  </div>
                </div>
              </div>
            </card>
          </modal>

          <modal :show.sync="showUpdateReservationDaysModal">
            <h3 class="card-title">{{$t('tools.setNumberOfDays')}}</h3>
            <div class="row">
              <div class="col-12">
                <base-input
                :label="$t('tools.numberOfDays')"
                :placeholder="$t('tools.updatedNumberOfDays')"
                v-model="updateDays"
                ></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-sm d-flex justify-content-end">
                <base-button @click="updateReservationDays" type="standard" fill>{{$t('common.submit')}}</base-button>
              </div>
            </div>
          </modal>

          <template v-slot:table-select>
            <div>
              <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <label
                  v-for="(option, index) in filteredTablesCategories"
                  :key="option.name"
                  class="btn btn-sm btn-simple"
                  :class="[{ active: activeIndex === index}, selectClass]"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="initTable(index)"
                    name="options"
                    autocomplete="off"
                    :checked="activeIndex === index"
                  />
                  <span class="d-none d-sm-block" :style="{ color: activeIndex === index ? 'white' : ''}">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon" :style="{ color: activeIndex === index ? 'white' : ''}"></i>
                  </span>
                </label>
              </div>
            </div>
          </template>
          <div v-if="tableDataReady">
            <div class="table-responsive">
              <el-base-table
                :tableData="myToolsTable.data"
                :tableColumns="myToolsTable.columns"
                :allowClick="true"
                @row-click="goToTool"
              />
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('pagination.showing') }} {{ myToolsFrom + 1 }} {{ $t('pagination.to') }} {{ myToolsTo }} {{ $t('pagination.of') }} {{ myToolsTotal }} {{ $t('pagination.entries') }}
                </p>
              </div>
              <base-pagination
                @input="myToolsUpdatePage"
                class="pagination-no-border"
                v-model="myToolsPagination.currentPage"
                :per-page="myToolsPagination.perPage"
                :total="myToolsTotal"
              >
              </base-pagination>
            </div>
          </div>
          <div v-if="externalReservationDataReady">
            <div class="table-responsive">
              <el-base-table
                :tableData="externalReservationsTable.data"
                :tableColumns="externalReservationsTable.columns"
                :allowClick="true"
                @row-click="goToTool"
              />
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('pagination.showing') }} {{ externalReservationFrom + 1 }} {{ $t('pagination.to') }} {{ externalReservationTo }} {{ $t('pagination.of') }} {{ externalReservationTotal }} {{ $t('pagination.entries') }}
                </p>
              </div>
              <base-pagination
                @input="externalReservationUpdatePage"
                class="pagination-no-border"
                v-model="externalReservationPagination.currentPage"
                :per-page="externalReservationPagination.perPage"
                :total="externalReservationTotal"
              >
              </base-pagination>
            </div>
          </div>
          <div v-if="carsTableDataReady">
            <div class="table-responsive">
              <el-base-table
                :tableData="myCarsTable.data"
                :tableColumns="myCarsTable.columns"
                :allowClick="true"
                @row-click="goToCar"
              />
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('pagination.showing') }} {{ myCarsFrom + 1 }} {{ $t('pagination.to') }} {{ myCarsTo }} {{ $t('pagination.of') }} {{ myCarsTotal }} {{ $t('pagination.entries') }}
                </p>
              </div>
              <base-pagination
                @input="myCarsUpdatePage"
                class="pagination-no-border"
                v-model="myCarsPagination.currentPage"
                :per-page="myCarsPagination.perPage"
                :total="myCarsTotal"
              >
              </base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import ElBaseTable from "../../components/ElBaseTable.vue";
import QrScanner from '@/components/QRScanner/QrScanner.vue'
import { BasePagination,Modal } from 'src/components';
import api from "../../services/api"
import {Select, Option,Table, TableColumn,Radio } from 'element-ui';
import swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";




export default {
    components: {
      'el-base-table': ElBaseTable,
      QrScanner,
      BasePagination,
      Modal,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      'el-radio': Radio
    },
    computed: {
      myToolsTo() {
        let highBound = this.myToolsFrom + this.myToolsPagination.perPage;
        if (this.myToolsTotal < highBound) {
          highBound = this.myToolsTotal;
        }
        return highBound;
      },
      myToolsFrom() {
        return this.myToolsPagination.perPage * (this.myToolsPagination.currentPage - 1);
      },
      myToolsTotal() {
        return this.myToolsPagination.total;
      },
      myCarsTo() {
        let highBound = this.myCarsFrom + this.myCarsPagination.perPage;
        if (this.myCarsTotal < highBound) {
          highBound = this.myCarsTotal;
        }
        return highBound;
      },
      myCarsFrom() {
        return this.myCarsPagination.perPage * (this.myCarsPagination.currentPage - 1);
      },
      myCarsTotal() {
        return this.myCarsPagination.total;
      },
      externalReservationTo() {
        let highBound = this.externalReservationFrom + this.externalReservationPagination.perPage;
        if (this.externalReservationTotal < highBound) {
          highBound = this.externalReservationTotal;
        }
        return highBound;
      },
      externalReservationFrom() {
        return this.externalReservationPagination.perPage * (this.externalReservationPagination.currentPage - 1);
      },
      externalReservationTotal() {
        return this.externalReservationPagination.total;
      },
      projectsTo() {
        let highBound = this.projectsFrom + this.projectsPagination.perPage;
        if (this.projectsTotal < highBound) {
          highBound = this.projectsTotal;
        }
        return highBound;
      },
      projectsFrom() {
        return this.projectsPagination.perPage * (this.projectsPagination.currentPage - 1);
      },
      projectsTotal() {
        return this.projectsPagination.total;
      },
      myTablesCategories() {
        return [{ name: this.$t('tools.myTools'), icon: 'tim-icons icon-settings', showUser:true }, { name: this.$t('cars.myCars'), icon: 'tim-icons icon-bus-front-12', showUser:true }, { name: this.$t('projects.toolsLoanedRentedOnProject'), icon: 'tim-icons icon-user-run', showUser:false }];
      },
      filteredTablesCategories() {
        return this.myTablesCategories.filter(option => option.showUser || this.userRole !== 'User');
      },
      selectClass() {
        let darkMode = localStorage.getItem('darkMode');
        // If darkMode does not exist in localStorage, default it to 'false'
        if (darkMode === null) {
          darkMode = 'false';
        }
        if (darkMode === 'false') {
          return 'btn-standard';
        } else {
          return 'btn-primary';
        }
      }
    },
    data() {
      return {
        myToolsTable: {
          title: this.$t('tools.myTools'),
          columns: [
            { prop: 'image', label: this.$t('common.image'), width: 80, mobileWidth: 100, showOnMobile: true, isImage: true },
            { prop: 'tool', label: this.$t('common.tool'), minWidth: 200 },
            { prop: 'project', label: this.$t('common.project'), minWidth: 100 },
            { prop: 'daysLeft', label: this.$t('tools.daysLeft'), minWidth: 60 },
            { prop: 'reservationDate', label: this.$t('tools.reservation'), minWidth: 60},
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.tool': 'tool', 'common.project':'project', 'tools.daysLeft': 'daysLeft', 'tools.reservation': 'reservationDate'}},
          ],
          data: [],
        },
        myCarsTable: {
          title: this.$t('cars.myCars'),
          columns: [
            { prop: 'name', label: this.$t('common.name'), minWidth: 200 },
            { prop: 'licencePlate', label: this.$t('cars.licencePlate'), minWidth: 200 },
            { prop: 'reservationDate', label: this.$t('tools.reservation'), minWidth: 80},
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.name': 'name', 'cars.licencePlate':'licencePlate','tools.reservation': 'reservationDate'}},
          ],
          data: [],
        },
        externalReservationsTable: {
          title: this.$t('projects.toolsLoanedRentedOnProject'),
          columns: [
            { prop: 'name', label: this.$t('common.tool'), minWidth: 200 },
            { prop: 'status', label: this.$t('tools.loanedRented'), minWidth: 80 },
            { prop: 'externalUser', label: this.$t('tools.loanedRentedTo'), minWidth: 90 },
            { prop: 'reservationDate', label: this.$t('tools.reservation'), minWidth: 80},
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.tool': 'name', 'common.status':'status', 'tools.loanedRentedTo': 'externalUser', 'tools.reservation': 'reservationDate'}},
          ],
          data: [],
        },
        projects: [],
        projectTableColumns: [
          { prop: 'key', label: this.$t('projects.key'), minWidth: 150 },
          { prop: 'name', label: this.$t('common.name'), minWidth: 200 },
        ],
        myToolsInitialPage: true,
        myCarsInitialPage: true,
        projectsInitialPage: true,
        externalReservationInitialPage: true,
        myToolsPagination: {
          perPage: 10,
          currentPage: 1,
          total: 0
        },
        myCarsPagination: {
          perPage: 10,
          currentPage: 1,
          total: 0
        },
        externalReservationPagination: {
          perPage: 10,
          currentPage: 1,
          total: 0
        },
        projectsPagination: {
          perPage: 5,
          currentPage: 1,
          total: 0
        },
        tableDataReady: false,
        carsTableDataReady: false,
        externalReservationDataReady: false,
        showProjectPickModal:false,
        showUpdateReservationDaysModal: false,
        activeIndex: 0,
        cardTitle: this.$t('tools.myTools'),
        searchQuery: '',
        selectedProject: null,
        isMobile: window.innerWidth <= 768,
        currentQRContent: null,
        updateDays: '',
        latestUnreservedId: '',
        showToolId: false,
        userRole: '',
        currentLatitude: '',
        currentLongitude: ''
      };
    },
    created() {
      window.addEventListener('resize', this.updateIsMobile);
      const token = Cookies.get('accessToken');
      const decodedToken = jwtDecode(token);
      this.userRole = decodedToken.role
      this.getCurrentLocation();
      this.fetchTableData();

      // Check if the showToolId cookie exists
      // TODO: this needs to be smarter if we forget to generate the customer settings before the client loads???
      if (!Cookies.get('showToolId')) {
        let url = "Customers/CustomerSettings?key=ShowToolId";
        api.get(url)
          .then(response => {
            if (response.data.length > 0) {
              Cookies.set('showToolId', response.data[0].value === 'true');
              this.showToolId = response.data[0].value === 'true';
            }
          })
          .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 2000,
            });
          });
      } else {
      // If the cookie exists, set the component data property
          this.showToolId = Cookies.get('showToolId') === 'true';
      }
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateIsMobile);
    },
    methods: {
      searchProjects(){
        this.projectsPagination.currentPage = 1;
        this.projectsPagination.total = 0;

        let getProjectsUrl = `/Project?currentPage=${this.projectsPagination.currentPage}&pageSize=${this.projectsPagination.perPage}&searchString=${this.searchQuery}&projectStatusId=2`
        api.get(getProjectsUrl)
        .then(response => {
            this.projectsPagination.total = response.data.totalItems;
            this.projects = response.data.result.map(item => {
            return {
                id: item.id,
                key: item.key,
                name: item.name,
            };
            });
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
      },
      handleClearSearchProjects(){
        this.fetchProjectTableData();
      },

      initTable(index) {
        this.activeIndex = index;

        if (this.activeIndex === 0) {
          this.externalReservationDataReady = false;
          this.carsTableDataReady = false;
          this.cardTitle = this.$t('tools.myTools');
          if (this.myToolsTable.data.length <= 0) {
            this.fetchTableData();
          }
          else {
            this.tableDataReady = true;
          }
        }
        else if (this.activeIndex === 1) {
          this.tableDataReady = false;
          this.externalReservationDataReady = false;
          this.cardTitle = this.$t('cars.myCars')
          if (this.myCarsTable.data.length <= 0) {
            this.fetchCarsData();
          }
          else {
            this.carsTableDataReady = true;
          }
        }
        else {
          this.tableDataReady = false;
          this.carsTableDataReady = false;
          this.cardTitle = this.$t('projects.toolsLoanedRentedOnProject')
          if (this.externalReservationsTable.data.length <= 0) {
            this.fetchExternalReservationData();
          }
          else {
            this.externalReservationDataReady = true;
          }
        }
      },
      async fetchCarsData() {
        let toolGroupReserveUrl = `/ToolGroup/Reservations/?forCurrentUser=true&currentPage=${this.myCarsPagination.currentPage}&pageSize=${this.myCarsPagination.perPage}`
        api.get(toolGroupReserveUrl)
        .then(response => {
          this.myCarsPagination.total = response.data.totalItems;
          this.myCarsTable.data = response.data.result.map(item => {
            let licencePlate = ''
            if(item.toolGroup.toolGroupProperties.filter(p => p.key === 'licencePlate'))
            {
              licencePlate = item.toolGroup.toolGroupProperties.filter(p => p.key === 'licencePlate')[0].value
            }
            return {
              toolGroupId: item.toolGroupId,
              name: item.toolGroup.name,
              licencePlate: licencePlate,
              reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            };
          });
          this.carsTableDataReady = true;
        })
        .catch(error => {
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
            console.error(error);
        });
      },
      async fetchTableData() {
        let toolReserveUrl = `/Tool/Reservations?forCurrentUser=true&currentPage=${this.myToolsPagination.currentPage}&pageSize=${this.myToolsPagination.perPage}`
        api.get(toolReserveUrl)
        .then(response => {
          this.myToolsPagination.total = response.data.totalItems;
          this.myToolsTable.data = response.data.result.map(item => {
            let daysLeft = ''
            if(item.tool.maxReservationDays && item.tool.maxReservationDays !== 0){
              const currentDate = new Date();
              const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
              const daysSinceReservation = Math.round((currentDate - new Date(item.reservedTimestamp)) / oneDayInMilliseconds);
              daysLeft = item.tool.maxReservationDays  - daysSinceReservation;
            }
            else{
              daysLeft = '∞'
            }

            // Add the prefix to the tool name if the cookie is true
            let toolName = item.tool.name;
            if (this.showToolId) {
              toolName = `#${item.tool.customerToolId} ${toolName}`;
            }

            return {
              reservationId: item.id,
              toolId: item.tool.id,
              image: 'data:image/jpeg;base64,' + item.tool.toolImageThumbnailBytes,
              tool: toolName,
              project: item.project.name,
              reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
              daysLeft: daysLeft,
            };
          });
          this.tableDataReady = true;
        })
        .catch(error => {
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
            console.error(error);
        });

      },
      async fetchProjectTableData(){
        let projectUrl = ''
        if(this.searchQuery === '')
        {
          projectUrl = `/Project?currentPage=${this.projectsPagination.currentPage}&pageSize=${this.projectsPagination.perPage}&projectStatusId=2`
        }
        else
        {
          projectUrl = `/Project?currentPage=${this.projectsPagination.currentPage}&pageSize=${this.projectsPagination.perPage}&searchString=${this.searchQuery}&projectStatusId=2`
        }
        api.get(projectUrl)
        .then(response => {
            this.projectsPagination.total = response.data.totalItems;
            this.projects = response.data.result.map(item => {
            return {
                id: item.id,
                key: item.key,
                name: item.name,
            };
            });
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
      },
      async fetchExternalReservationData() {
        let exernalReservationUrl = `/Tool/Reservations/External?forCurrentUser=true&currentPage=${this.externalReservationPagination.currentPage}&pageSize=${this.externalReservationPagination.perPage}`
        api.get(exernalReservationUrl)
        .then(response => {
          this.externalReservationPagination.total = response.data.totalItems;
          this.externalReservationsTable.data = response.data.result.map(item => {
            let loanRent = item.rented ? this.$t('tools.rented') : loanRent = this.$t('tools.loaned');
            // Add the prefix to the tool name if the cookie is true
            let toolName = item.tool.name;
            if (this.showToolId) {
              toolName = `#${item.tool.customerToolId} ${toolName}`;
            }

            return {
              toolId: item.tool.id,
              name: toolName,
              externalUser: item.externalUser,
              status: loanRent,
              reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            };
          });
          this.externalReservationDataReady = true;
        })
        .catch(error => {
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
            console.error(error);
        });
      },
      myToolsUpdatePage (item) {
        this.myToolsPagination.currentPage = item;
        this.fetchTableData();
      },
      projectsUpdatePage (item) {
        this.projectsPagination.currentPage = item;
        this.fetchProjectTableData();
      },
      myCarsUpdatePage (item) {
        this.myCarsPagination.currentPage = item;
        this.fetchCarsData();
      },
      externalReservationUpdatePage (item) {
        this.externalReservationPagination.currentPage = item;
        if (this.externalReservationInitialPage) {
          this.externalReservationInitialPage = false;
        }
        else {
          this.fetchExternalReservationData();
        }
      },
      goToTool(item) {
          this.$router.push({ name: 'Tool', params: { toolId: item.toolId } });
      },
      goToCar(item) {
        this.$router.push({ name: 'Car', params: { toolGroupId: item.toolGroupId.toString() } });
      },
      async reserveTool(){
        if(this.selectedProject === null){
          this.$notify({
                message: this.$t('tools.pickProject'),
                icon: "tim-icons icon-alert-circle-exc",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
                timeout: 2000,
              });
              return;
        }
        try {
          const response = await api.post('/Tool/Reserve', {
            toolId: this.currentQRContent,
            projectId: this.selectedProject
          });
          this.$notify({
            message: this.$t('tools.toolReserved'),
            icon: "tim-icons icon-check-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 1500,
          });
          this.showProjectPickModal = false;
          this.selectedProject = null;
          this.searchQuery = ''

          this.projectsPagination= {
            perPage: 5,
            currentPage: 1,
            total: 0
          }


        }
        catch (error) {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('tools.unableToReserveTool'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        }
        this.fetchTableData();
      },

      async selectProject(qrContent){
        //validate QRContent
        qrContent = qrContent.split("/").pop();
        if(this.validateUuid(qrContent)){
          this.currentQRContent = qrContent
        }
        else{
          this.$notify({
            message: this.$t('tools.invalidToolId') + " " + this.currentQRContent,
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
          return;
        }

        //Check info of tool
        let statusUrl = `/Tool/Info/${this.currentQRContent}`
        api.get(statusUrl)
        .then(response => {
            let needUserConfirmation = false
            let confirmationInfo = ''
            let confirmationQuestion = this.$t('tools.doYouWantToOvertake')
            let unreserve = false
            let externalUnreserve = false
            let takeOverCar = false
            if(response.data.isReserved === true){
              needUserConfirmation = true
              unreserve = true
              confirmationInfo = this.$t('tools.reservedByOtherUser') + ' ' + response.data.reservedBy
            }
            else if(response.data.isExternallyReserved === true){
              externalUnreserve = true
              needUserConfirmation = true
              confirmationInfo = this.$t('tools.loanedRentedByOtherUser') + ' ' + response.data.externallyReservedBy
            }
            else if(response.data.isInToolGroup === true){
              takeOverCar = true
              needUserConfirmation = true
              confirmationInfo = this.$t('tools.toolIsInCar') + ' ' + response.data.toolGroupName
              if(response.data.isToolGroupReserved){
                confirmationInfo = confirmationInfo + ' ' + this.$t('tools.carReservedBy') + ' ' + response.data.toolGroupReservedBy
              }
            }

            if(needUserConfirmation === true){
              swal.fire({
              title: confirmationInfo,
              text: confirmationQuestion,
              icon: 'warning',
              showCancelButton: true,
              customClass: {
                confirmButton: 'btn btn-success btn-fill',
                cancelButton: 'btn btn-danger btn-fill'
              },
              confirmButtonText: this.$t('tools.yesOvertake'),
              cancelButtonText: this.$t('delete.cancel'),
              buttonsStyling: false
            }).then(result => {
              if (result.value) {
                let takeOverUrl = ''
                if(unreserve === true){
                  takeOverUrl = `/Tool/Unreserve/${this.currentQRContent}`
                }
                else if(externalUnreserve === true){
                  takeOverUrl = `/Tool/Unreserve/External/${this.currentQRContent}`
                }
                else if(takeOverCar=== true){
                  this.fetchProjectTableData();
                  this.showProjectPickModal = true
                  return;
                }

                api.put(takeOverUrl).then(response => {
                  this.fetchProjectTableData();
                  this.showProjectPickModal = true
                })
                .catch(error => {
                  console.error('API request error:', error);
                  this.$notify({
                    message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
                    icon: "tim-icons icon-alert-circle-exc",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger",
                    timeout: 0,
                  });
                }
              )
              .catch(error => {
                  console.error('API request error:', error);
                  this.$notify({
                    message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
                    icon: "tim-icons icon-alert-circle-exc",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger",
                    timeout: 0,
                  });
                }

              );
              }
              else{
                return;
              }
            });
            }
            else{
              this.fetchProjectTableData();
              this.showProjectPickModal = true
            }
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
      },
      async unreserve(qrContent) {
        try {
          if (this.isMobile) {
            qrContent = qrContent.split("/").pop();
          }
          if(this.validateUuid(qrContent))
          {
            //Check if current user has tool reserved
            let statusUrl = `/Tool/Info/${qrContent}`
            api.get(statusUrl)
            .then(response => {
              let errorMessage = ''
              const token = Cookies.get('accessToken');
              const decodedToken = jwtDecode(token);
              let userId = decodedToken.UserIdentifier
              if(response.data.isReserved !== true){
                errorMessage = this.$t('tools.toolNotReserved')
              }
              else if(response.data.userId !== userId){
                errorMessage = this.$t('tools.reservedByDifferentUser') + " " + response.data.reservedBy
              }

              if(response.data.locationInfo.requirePresenceForToolReturn === true)
              {
                const distance = this.calculateDistance(this.currentLatitude, this.currentLongitude, response.data.locationInfo.latitude, response.data.locationInfo.longitude);
                if (distance > response.data.locationInfo.radiusMeters) {
                  errorMessage = this.$t('tools.toolNotAtLocation') + ': ' + response.data.locationInfo.address
                }
              }

              if(errorMessage !== ''){
                this.$notify({
                  message: errorMessage,
                  icon: "tim-icons icon-alert-circle-exc",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "danger",
                  timeout: 0,
                });
              }
              else{
                let unreserveUrl = `/Tool/Unreserve/${qrContent}`;
                api.put(unreserveUrl).then(response => {
                  this.fetchTableData();

                  //Check if user wants to override totalprice
                  if(response.data.totalPrice && response.data.totalPrice>0){
                    this.latestUnreservedId = response.data.id
                    let totalDays = this.calculateDays(response.data.reservedTimestamp, response.data.unreservedTimestamp);
                    swal.fire({
                    title: this.$t('tools.successfulUnreserve'),
                    text: `${this.$t('tools.youHadToolFor')} ${totalDays} ${this.$t('tools.daysPrice')} ${response.data.totalPrice} Kr.`,
                    icon: 'success',
                    showCancelButton: true,
                    customClass: {
                      confirmButton: 'btn btn-success btn-fill',
                      cancelButton: 'btn btn-danger btn-fill'
                    },
                    confirmButtonText: this.$t('tools.acceptTotalPrice'),
                    cancelButtonText: this.$t('tools.updateReservedDays'),
                    buttonsStyling: false
                    }).then(result => {
                    if (!result.value) {
                      this.showUpdateReservationDaysModal = true;
                    }
                    else{
                      this.$notify({
                        message: this.$t('tools.successfulUnreserve'),
                        icon: "tim-icons icon-check-2",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "success",
                        timeout: 1500,
                      });
                    }
                    });
                  }
                  else{
                    this.$notify({
                      message: this.$t('tools.successfulUnreserve'),
                      icon: "tim-icons icon-check-2",
                      horizontalAlign: "center",
                      verticalAlign: "top",
                      type: "success",
                      timeout: 1500,
                    });
                  }
                })
                .catch(error => {
                  console.error('API request error:', error);
                  this.$notify({
                    message: this.$t('common.toolReturnError'),
                    icon: "tim-icons icon-alert-circle-exc",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger",
                    timeout: 0,
                  });
                });
              }
            })
            .catch(error => {
              console.error('API request error:', error);
              this.$notify({
                message: this.$t('common.unableToRetrieveData'),
                icon: "tim-icons icon-alert-circle-exc",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
                timeout: 0,
              });
            });
          }  else {
            this.$notify({
              message: this.$t('tools.invalidToolId') + ": " + toolId,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 2000,
            });
          }
        }
        catch (error) {
          this.$notify({
            message: this.$t('tools.toolReturnError'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
          console.error(error);
        }
      },
      validateUuid(uuid) {
        const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        return uuidRegex.test(uuid);
      },
      updateReservationDays(){
        if (!Number.isInteger(Number(this.updateDays)) || this.updateDays === '') {
          this.$notify({
            message: this.$t('common.needsToBeNumber'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
          return;
        }

        api.put(`/Tool/Reservation/${this.latestUnreservedId}`,{days: this.updateDays}).then(response => {
          this.showUpdateReservationDaysModal = false;
          this.updateDays = ''
          swal.fire({
            title: `${this.$t('tools.updatedPrice')} ${response.data.totalPrice} Kr.`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-fill'
            },
            buttonsStyling: false
          });
        })
        .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
          });

      },
      updateIsMobile() {
        this.isMobile = window.innerWidth <= 768;
      },
      getCurrentLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            this.currentLatitude = position.coords.latitude;
            this.currentLongitude = position.coords.longitude;
          }, error => {
            console.error(error);
          });
        } else {
            this.$notify({
              message: "Geolocation is not supported by this browser.",
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "warning",
              timeout: 0,
            });
        }
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
        const R = 6371e3; // Radius of the Earth in meters
        const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
        const φ2 = lat2 * Math.PI / 180;
        const Δφ = (lat2 - lat1) * Math.PI / 180;
        const Δλ = (lon2 - lon1) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                  Math.cos(φ1) * Math.cos(φ2) *
                  Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = R * c; // in meters
        return distance;
      },
    calculateDays(date1, date2) {
      var date1_ms = new Date(date1).getTime();
      var date2_ms = new Date(date2).getTime();

      // Calculate the difference in milliseconds
      var difference_ms = Math.abs(date1_ms - date2_ms);

      // Convert back to days and return
      return Math.ceil(difference_ms / (1000 * 60 * 60 * 24));
    }
  }
};
</script>
<style scoped>
.unreserve-tools {
    display: flex;
    align-items: center;
    gap: 10px;
}
.el-radio__label{
  display: none !important;
}
</style>
