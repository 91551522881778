<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card :title="computedTitle" :onCancel="cancel">
    <div class="row">
      <div class="col-md-4">
        <label class="col-md-4 col-form-label">{{$t('locations.address')}} *</label>
        <base-input
          ref="addressInput"
          v-model="address"
          :placeholder="$t('locations.address')"
        >
        </base-input>
      </div>

      <div class="col-md-4">
        <label class="col-md-4 col-form-label">{{$t('common.type')}} *</label>
        <div class="row" style="padding-left: 18px;">

         <el-select
              ref="locationTypeInput"
              v-if="selectedLocationType !== 'custom'"
              :class="selectClass"
              size="medium"
              style="width: 365px;"
              :placeholder="$t('common.selectType')"
              v-model="selectedLocationType"
            >
              <el-option
                v-for="item in options"
                :class="selectClass"
                :value="item.type"
                :label="item.type"
                :key="item.id"
              >
              </el-option>
                <el-option
                  :class="selectClass"
                  value="custom"
                  :label="$t('common.addNewType')"
                >
                </el-option>
          </el-select>

          <base-input
            ref="newLocationTypeInput"
            v-model="newLocationType"
            style="width: 340px;"

            v-if="selectedLocationType === 'custom'"
            :placeholder="$t('common.type')"
            required
          >
          </base-input>
          <span v-if="selectedLocationType === 'custom'" class="cancel-new-type" @click="cancelNewLocationType">
            <i class="tim-icons icon-simple-remove"></i>
          </span>
        </div>

      </div>
      <div class="col-md-4" v-bind:style="{ paddingTop: !isMobile ? '40px' : '10px' }">
        <base-checkbox
          v-model="requirePresenceForToolReturn"
        >
          {{$t('locations.requirePresenceForToolReturn')}}
        </base-checkbox>
       </div>

    </div>
    <hr v-if="isMobile">

    <div class="row">
      <div class="col-md-4">
        <label class="col-md-4 col-form-label">{{$t('locations.latitude')}}</label>
        <base-input
          v-model="latitude"
          :placeholder="$t('locations.latitude')"
        >
        </base-input>
      </div>
      <div class="col-md-4">
        <label class="col-md-4 col-form-label">{{$t('locations.longitude')}}</label>

        <base-input
          v-model="longitude"
          :placeholder="$t('locations.longitude')"
        >
        </base-input>
      </div>
      <div class="col-md-4">
        <label class="col-md-4 col-form-label">{{$t('locations.radius')}}</label>

        <base-input
          v-model="radius"
          :placeholder="$t('locations.radius')"
        >
        </base-input>
      </div>
    </div>
    <div class="row">

      <div class="col-sm d-flex justify-content-end">
        <base-button @click="saveLocation" type="standard" fill>{{$t('common.submit')}}</base-button>
      </div>
    </div>
  </card>
</template>
<script>
import api from "../../services/api"
import {Select, Option } from 'element-ui';


export default {
  props: {
    editMode: {
      type: Boolean,
      required: true
    },
    longitudeProp: {
      type: String,
      required: false
    },
    latitudeProp: {
      type: String,
      required: false
    },
    addressProp: {
      type: String,
      required: false
    },
    selectedLocationTypeProp: {
      type: String,
      required: false
    },
    requirePresenceForToolReturnProp: {
      type: Boolean,
      required: false
    },
    editingLocationId: {
      type: Number,
      required: false
    },
    radiusProp: {
      type: String,
      required: false
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  computed: {
    computedTitle() {
      return this.editMode ? this.$t('locations.editLocation') : this.$t('locations.createLocation');
    },
    selectClass() {
      let darkMode = localStorage.getItem('darkMode');
      // If darkMode does not exist in localStorage, default it to 'false'
      if (darkMode === null) {
        darkMode = 'false';
      }
      if (darkMode === 'false') {
        return 'select-standard';
      } else {
        return 'select-primary';
      }
    }
  },
  data() {
    return {
      options: [],
      isMobile: window.innerWidth <= 768,

      selectedLocationType: this.selectedLocationTypeProp,
      longitude: this.longitudeProp,
      latitude: this.latitudeProp,
      address: this.addressProp,
      newLocationType: '',
      requirePresenceForToolReturn: this.requirePresenceForToolReturnProp,
      radius: this.radiusProp
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    cancelNewLocationType(){
      if(this.options.length>0){
        this.selectedLocationType = this.options[0].type
      }
      else{
        this.selectedLocationType = ''
      }
    },
    cancel()
    {
      this.$emit('close');
    },
    async saveLocation() {
      if (this.validateInput()) {
        let locationtypeid = 0
        if(this.newLocationType !== '')
        {
          const newLocationTypeResponse = await api.post('/LocationType', {
            type: this.newLocationType,
          });
          locationtypeid = newLocationTypeResponse.data.id
        }
        else
        {
          locationtypeid = this.options.filter(op => op.type === this.selectedLocationType)[0].id
        }
        if(this.latitude === ''){
          this.latitude = null
        }
        if(this.longitude === ''){
          this.longitude = null
        }
        if(this.radius === ''){
          this.radius = null
        }

        if(this.editMode)
        {
        const response = await api.put('/Location/' + this.editingLocationId , {
          address: this.address,
          latitude: this.latitude,
          longitude: this.longitude,
          requirePresenceForToolReturn: this.requirePresenceForToolReturn,
          radiusMeters: this.radius,
          locationtypeid: locationtypeid
          }).then(request => {
              this.$notify({
                message: this.$t('locations.locationUpdated'),
                icon: "tim-icons icon-check-2",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
                timeout: 1500,
              });

              this.$emit('refreshData');
              this.$emit('close');
          }).catch(error => {
            console.error(error)
            this.$notify({
              message: `Unable to create location: ${error.message}`,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
        });
        }
        else
        {
        const response = await api.post('/Location', {
          address: this.address,
          latitude: this.latitude,
          longitude: this.longitude,
          requirePresenceForToolReturn: this.requirePresenceForToolReturn,
          radiusMeters: this.radius,
          locationtypeid: locationtypeid
          }).then(request => {
              this.$notify({
                message: this.$t('locations.locationCreated'),
                icon: "tim-icons icon-check-2",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
                timeout: 1500,
              });

              this.$emit('refreshData');
              this.$emit('close');
          }).catch(error => {
            console.error(error)
            this.$notify({
              message: `Unable to create location: ${error.message}`,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
        });
        }

      }
    },
    fetchData()
    {
      let getLocationTypessUrl = `/LocationType`
      api.get(getLocationTypessUrl)
      .then(response => {
          this.options = response.data
      })
      .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: `Unable to retrieve location type data: ${error.message} <br> Please refresh the page`,
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
      });
    },
    validateInput() {
      let success = true;
      if (this.address === '') {
        this.$refs.addressInput.focus();
        this.$notify({
          message: this.$t('errors.addressEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.selectedLocationType === 'custom' && this.newLocationType === '') {
        this.$refs.newLocationTypeInput.focus();
        this.$notify({
          message: this.$t('errors.newLocationTypeEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.selectedLocationType === '') {
        this.$refs.locationTypeInput.focus();
        this.$notify({
          message: this.$t('errors.locationEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if(this.checkIfNumber(this.latitude) === false || this.checkIfNumber(this.longitude) === false)
      {
        this.$notify({
          message: this.$t('errors.latLongMustBeNumber'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if(this.requirePresenceForToolReturn === true && (this.radius === '' || this.latitude === '' || this.longitude === ''))
      {
        this.$notify({
          message: this.$t('errors.radiusLatLongMustBeSetIfPresenceRequired'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      return success;
    },
    checkIfNumber(input) {
      if (!isNaN(input)) {
        return true
      } else {
        return false
      }
    }

  }
};
</script>
<style></style>
