<template>
  <div class="row">
   <div class="col-md-12">
    <template v-if="enableEdit">
      <card class="stacked-form" :title="$t('tools.updateTool')" :onCancel="handleCancel">
        <form @submit.prevent="handleUpdate">
          <div class="row">
            <div class="col-md-6">
                <label class="col-md-3 col-form-label">{{$t('common.name')}} *</label>
                <div class="col-md-9">
                  <base-input :placeholder="$t('common.name')" ref="nameInput" v-model=tool.name> </base-input>
                </div>

                <label class="col-md-3 col-form-label">{{$t('common.description')}} *</label>
                <div class="col-md-9">
                  <base-input :placeholder="$t('common.description')" ref="descriptionInput" v-model=tool.description> </base-input>
                </div>

                <label class="col-md-3 col-form-label">Serial</label>
                <div class="col-md-9">
                  <base-input placeholder="Serial"  v-model=tool.sku> </base-input>
                </div>

                <label class="col-md-3 col-form-label">{{$t('locations.location')}} *</label>
                <div class="col-md-9">
                  <el-select
                    ref="locationInput"
                    :class="selectClass"
                    size="large"
                    :placeholder="$t('tools.selectLocation')"
                    v-model="locationOption"
                  >
                    <el-option
                      v-for="item in locationOptions"
                      :class="selectClass"
                      :value="item.address"
                      :label="item.address"
                      :key="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>

                <label class="col-md-3 col-form-label">{{$t('common.type')}} *</label>
                <div v-if="toolTypeOption !== 'New Type'" class="col-md-9">
                  <el-select
                    ref="locationTypeInput"
                    :class="selectClass"
                    size="large"
                    :placeholder="$t('tools.selectType')"
                    allow-create
                    v-model="toolTypeOption"
                  >
                    <el-option
                      v-for="item in toolTypeOptions"
                      :class="selectClass"
                      :value="item.type"
                      :label="item.type"
                      :key="item.id"
                    >
                    </el-option>
                    <el-option value="New Type">{{$t('common.addNewType')}}</el-option>
                  </el-select>
                </div>
                <div class="new-type" v-if="toolTypeOption === 'New Type'">
                  <div class="row">
                    <base-input ref="newToolTypeInput"
                    :placeholder="$t('tools.newToolType')" v-model=newToolType> </base-input>
                  <span class="cancel-new-type" @click="cancelNewToolType">
                    <i class="tim-icons icon-simple-remove"></i>
                  </span>
                  </div>
                </div>

                <label class="col-md-3 col-form-label">{{$t('tools.dailyPrice')}}</label>
                <div class="col-md-9 form-control-static">
                  <base-input :placeholder="$t('tools.dailyPrice')"  v-model=tool.dailyPrice> </base-input>
                </div>
                <label class="col-md-3 col-form-label">{{$t('tools.isle')}}</label>
                <div class="col-md-9 form-control-static">
                  <base-input :placeholder="$t('tools.isle')"  v-model=tool.isle> </base-input>
                </div>
                <label class="col-md-3 col-form-label">{{$t('tools.shelf')}}</label>
                <div class="col-md-9 form-control-static">
                  <base-input :placeholder="$t('tools.shelf')"  v-model=tool.shelf> </base-input>
                </div>

            </div>
            <div class="col-md-6">
              <div v-if="!isMobile" class="col-md-9">
                <image-upload :showUploadButton="false" @change="handleFileUpload" :src="'data:image/png;base64,' + tool.toolImageBytes" :select-text="$t('image.selectImage')" />
              </div>

              <label class="col-md-3 col-form-label">{{$t('tools.brand')}}</label>
              <div class="col-md-9">
                <base-input :placeholder="$t('tools.brand')"  v-model=tool.brandName> </base-input>
              </div>

              <label class="col-md-5 col-form-label">{{$t('tools.maxReservationDays')}}</label>
              <div class="col-md-9">
                <base-input :placeholder="$t('tools.maxReservationDays')"  v-model=tool.maxReservationDays> </base-input>
              </div>
                <label class="col-md-3 col-form-label">{{$t('tools.purchaseDate')}}</label>
                <div class="col-md-9">
                  <base-input>
                    <el-date-picker
                      type="date"
                      :placeholder="$t('tools.purchaseDate')"
                      v-model="tool.purchaseDate"
                    >
                    </el-date-picker>
                  </base-input>
                </div>
              <label class="col-md-5 col-form-label">{{$t('tools.purchasePrice')}}</label>
              <div class="col-md-9">
                <base-input :placeholder="$t('tools.purchasePrice')"  v-model=tool.purchasePrice> </base-input>
              </div>
              <div v-if="isMobile" class="col-md-9">
                <image-upload :showUploadButton="false" @change="handleFileUpload" :src="'data:image/png;base64,' + tool.toolImageBytes" :select-text="$t('image.selectImage')" />
              </div>
            </div>
            <div class="col-sm d-flex justify-content-end">
              <base-button class="mt-3" native-type="submit" type="standard"
                >{{$t('common.submit')}}</base-button
              >
            </div>

          </div>
        </form>
      </card>
    </template>



    <template v-else>
      <div class="row">
        <div class="col-md-6">
          <card>
            <h2>{{ toolDisplayName }}</h2>
            <img v-if="tool.toolImageBytes" class="toolImage" :src="'data:image/png;base64,' + tool.toolImageBytes" alt="tool alt text" />
            <h3>{{toolStatus}}
              <div v-if="employee !== ''" class="tool-info"><h4><strong>{{$t('tools.employee')}}: </strong> {{ employee }}</h4></div>
              <div v-if="projectName !== ''" class="tool-info"><h4><strong>{{$t('common.project')}}: </strong> {{ projectName }}</h4></div>
              <div v-if="reservedTimestamp !== ''" class="tool-info"><h4><strong>{{$t('common.date')}}: </strong> {{ reservedTimestamp }}</h4></div>
              <div v-if="car !== ''" class="tool-info"><h4><strong>{{$t('cars.car')}}: </strong> {{ car }}</h4></div>
              <div v-if="externalUserLoanRent !== ''" class="tool-info"><h4><strong>{{$t('tools.loanedRentedTo')}}: </strong> {{ externalUserLoanRent }}</h4></div>
            </h3>
          </card>

          <modal :show.sync="showUpdateReservationDaysModal">
            <h3 class="card-title">{{$t('tools.setNumberOfDays')}}</h3>
            <div class="row">
              <div class="col-12">
                <base-input
                :label="$t('tools.numberOfDays')"
                :placeholder="$t('tools.updatedNumberOfDays')"
                v-model="updateDays"
                ></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-sm d-flex justify-content-end">
                <base-button @click="updateReservationDays" type="standard" fill>{{$t('common.submit')}}</base-button>
              </div>
            </div>
          </modal>

          <div class="row">
            <div class="col-md-12">
              <div class="row">
              <div class="col-md-12">
                <rent-loan-form v-if="showRentLoanForm" :toolId="this.toolId" @close="closeRentLoanForm" @refreshData="fetchData"></rent-loan-form>
              </div>

              </div>
              <div class="row">
                <div v-if="this.userRole !== 'User'" class="col-md-6">
                  <base-button block type="standard btn-simple" @click="handleEdit" >{{$t('common.edit')}}</base-button>
                </div>
                <div v-if="this.userRole !== 'User'" class="col-md-6">
                  <base-button block type="warning" @click="handleDelete" >{{$t('common.delete')}}</base-button>
                </div>
              </div>
              <div class="row">
                <div v-if="!this.isMobile && this.userRole !== 'User'" class="col-md-6">
                  <base-button block type="standard" @click="printQRCode">{{$t('tools.printQR')}}</base-button>
                </div>
                <div v-if="this.userRole !== 'User' && !this.isExternalReserved && !this.isReserved && this.toolStatus === $t('toolStatus.free') && this.car ===''" class="col-md-6">
                  <base-button block type="standard" @click="openRentLoanForm">{{$t('tools.loanRent')}}</base-button>
                </div>
                  <div v-if="this.userRole !== 'User' && this.isExternalReserved" class="col-md-6">
                    <base-button block type="standard" @click="returnTool">{{$t('tools.return')}}</base-button>
                </div>
              </div>
              <div class="row">
                <div v-if="userRole !== 'User'" v-for="option in updateToolStatusOptions" :key="option.id" class="col-md-6">
                  <base-button block type="standard" @click="changeStatus(option.status)">
                    <i v-if="option.loading" class="fa fa-spinner fa-spin"></i> <!-- Assuming you use font-awesome for spinning icon -->
                    {{ option.status }}
                  </base-button>
                </div>
              </div>

            </div>
          </div>

        </div>
        <div class="col-md-6">
          <card>
          <h2>{{$t('common.info')}}</h2>
          <div class="row">
            <div class="col-md-8">
              <h4><strong>{{$t('common.description')}}: </strong> {{ tool.description }}</h4>
              <h4><strong>{{$t('tools.brand')}}: </strong>{{ tool.brandName }}</h4>
              <h4><strong>{{$t('locations.location')}}: </strong> {{ locationOption }}</h4>
              <h4><strong>{{$t('common.type')}}: </strong> {{ toolTypeOption }}</h4>
              <h4><strong>{{$t('tools.dailyPrice')}}: </strong> {{ tool.dailyPrice }} Kr.</h4>
              <h4><strong>{{$t('tools.purchaseDate')}}: </strong> {{ purchaseDateGoodFormat }}</h4>
              <h4><strong>{{$t('tools.purchasePrice')}}: </strong> {{ tool.purchasePrice }}</h4>
              <h4><strong>{{$t('tools.isle')}}: </strong> {{ tool.isle }}</h4>
              <h4><strong>{{$t('tools.shelf')}}: </strong> {{ tool.shelf }}</h4>
              <h4><strong>{{$t('tools.maxReservationDays')}}: </strong> {{ maxReservationDaysDisplayValue }}</h4>
              <h4><strong>Serial: </strong> {{ tool.sku }}</h4>
            </div>
            <div v-if="this.userRole !== 'User'" class="col-md-4">
              <div class="qr-container" v-if="tool.id">
                <qrcode-vue
                  render-as="svg"
                  :value="qrValue"
                  :size="qrDisplaySize"
                  level="H"
                  background="#ffffff"
                  foreground="#000000"
                  ref="printqrcode"
                >
                </qrcode-vue>
              </div>
            </div>
          </div>

          </card>

        </div>
      </div>
    </template>

    <div class="row" v-show="false">
      <card type="toolqr">
        <p class="card-text"></p>
        <div class="qr-print-container" v-if="tool.id">
          <qrcode-vue
            render-as="svg"
            :value="qrValue"
            :size="qrPrintSize"
            level="H"
            background="#ffffff"
            foreground="#000000"
            ref="printqrcode"
          >
          </qrcode-vue>
          <h3>{{ toolDisplayName }}</h3>
        </div>
      </card>
    </div>
   </div>
  </div>
</template>

<script>
import api from "../../services/api";  // Import your API service
import QrcodeVue from 'qrcode.vue';  // Import QrcodeVue from qrcode.vue
import config from "@/config";
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { DatePicker, Select, Option } from 'element-ui';
import { ImageUpload } from 'src/components/index';
import RentLoanForm from "./RentLoanForm";
import swal from 'sweetalert2';
import { Modal } from 'src/components';




export default {
  data() {
    return {
      purchaseDateGoodFormat:"",
      isReserved: false,
      isExternalReserved: false,
      showRentLoanForm: false,
      toolId: 0,
      tool: {},  // This will hold the tool data
      onCreatedTool: {}, // This will store the original tool values so we don't have to call fetch data again on cancel
      includeOwnerInformation: true,
      enableEdit: false,
      toolTypeOptions: [],
      locationOptions: [],
      updateToolStatusOptions: [
        {id: 1, status: this.$t('toolStatus.free'), loading: false},
        {id: 3, status: this.$t('toolStatus.broken'), loading: false},
        {id: 4, status: this.$t('toolStatus.outOfUse'), loading: false},
      ],
      toolTypeOption: "",
      locationOption: "",
      updatedStatus:"",
      toolStatus:"",
      toolTypeId: "",
      newToolType: "",
      maxReservationDaysDisplayValue: "",
      userRole: null,
      qrDisplaySize: 128,
      qrPrintSize: 256,
      isMobile: window.innerWidth <= 768,
      employee: "",
      reservedTimestamp: "",
      projectName: "",
      car:"",
      externalUserLoanRent: "",
      showToolId: Cookies.get('showToolId'),
      toolDisplayName: "",
      showUpdateReservationDaysModal: false,
      updateDays: '',
      latestUnreservedId: '',
      loading: false,
    };
  },
  created() {

    this.toolId = this.$route.params.toolId;
    const token = Cookies.get('accessToken');
    const decodedToken = jwtDecode(token);
    this.userRole = decodedToken.role
    this.fetchData();

    if(this.$route.params.editMode)
    {
      this.handleEdit();
    }
  },
  components: {
    QrcodeVue,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
    ImageUpload,
    RentLoanForm
  },
  computed: {
    qrValue() {
      // Combine the base URL with the tool ID to create the full URL
      let qrUrl = `${config.baseUrl}/#/tool/${this.tool.id}`;
      return qrUrl;
    },
    selectClass() {
      let darkMode = localStorage.getItem('darkMode');
      // If darkMode does not exist in localStorage, default it to 'false'
      if (darkMode === null) {
        darkMode = 'false';
      }
      if (darkMode === 'false') {
        return 'select-standard';
      } else {
        return 'select-primary';
      }
    }
  },
  methods: {
    changeStatus(status) {
      const clickedOption = this.updateToolStatusOptions.find(option => option.status === status);
      if (clickedOption) {
        clickedOption.loading = true;

        this.updatedStatus = status;
        this.handleUpdate().finally(() => {
          // After the operation is complete, stop loading
          clickedOption.loading = false;
        });
      }
    },
    cancelNewToolType(){

      if(this.toolTypeOptions.length>0){
        this.toolTypeOption = this.toolTypeOptions[0].type
      }
      else{
        this.toolTypeOption = ''
      }
    },
    async returnTool(){
      swal.fire({
        title: this.$t('tools.areYouSureReturn'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: this.$t('tools.yesReturnIt'),
        cancelButtonText: this.$t('delete.cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          api.put(`/Tool/Unreserve/External/${this.toolId}`).then(response => {
            //Check if user wants to update reservation days IF this is a rent (not loan):

            this.fetchData();
            if(response.data.totalPrice && response.data.totalPrice>0){
              this.latestUnreservedId = response.data.id
              let totalDays = this.calculateDays(response.data.reservedTimestamp, response.data.unreservedTimestamp);
              swal.fire({
                title: this.$t('tools.successfulUnreserve'),
                text: `${this.$t('tools.youHadToolFor')} ${totalDays} ${this.$t('tools.daysPrice')} ${response.data.totalPrice} Kr.`,
                icon: 'success',
                showCancelButton: true,
                customClass: {
                  confirmButton: 'btn btn-success btn-fill',
                  cancelButton: 'btn btn-danger btn-fill'
                },
                confirmButtonText: this.$t('tools.acceptTotalPrice'),
                cancelButtonText: this.$t('tools.updateReservedDays'),
                buttonsStyling: false
                }).then(result => {
                if (!result.value) {
                  this.showUpdateReservationDaysModal = true;
                }
                else{
                  swal.fire({
                    title: this.$t('tools.returned'),
                    text: `${this.$t('tools.youReturned')} ${this.tool.name}`,
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-success btn-fill',
                    },
                    buttonsStyling: false
                  });
                }
                });
            }
            else{

              swal.fire({
                title: this.$t('tools.returned'),
                text: `${this.$t('tools.youReturned')} ${this.tool.name}`,
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-success btn-fill',
                },
                buttonsStyling: false
              });
            }



        })
        .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
          });
        }
      });
    },
    openRentLoanForm(){
      this.showRentLoanForm = true
    },
    closeRentLoanForm(){
      this.showRentLoanForm = false
    },
    setUpdateStatusOptions(){
      if(this.toolStatus === this.$t('toolStatus.free')){
        this.updateToolStatusOptions = [{id: 3, status: this.$t('toolStatus.broken'), loading: false }, {id: 4, status: this.$t('toolStatus.outOfUse'), loading: false }]
      }
      else if(this.toolStatus === this.$t('toolStatus.broken')){
        this.updateToolStatusOptions = [{id: 1, status: this.$t('toolStatus.free'), loading: false }, {id: 4, status: this.$t('toolStatus.outOfUse'), loading: false }]
      }
      else if(this.toolStatus === this.$t('toolStatus.outOfUse')){
        this.updateToolStatusOptions = [{id: 1, status: this.$t('toolStatus.free'), loading: false }, {id: 3, status: this.$t('toolStatus.broken'), loading: false }]
      }
    },
    fetchData() {
      let toolUrl = `/Tool/${this.toolId}`;
      api.get(toolUrl)
        .then(response => {
          //This is a bit of a hack to store the original value by deep copying the response.data
          this.tool = JSON.parse(JSON.stringify(response.data));
          this.purchaseDateGoodFormat = this.tool.purchaseDate.split('T')[0];
          this.toolStatus = this.getStatusTranslation(this.tool.toolStatusId)

          this.updatedStatus = this.toolStatus
          this.onCreatedTool = JSON.parse(JSON.stringify(response.data));
          this.locationOption = this.tool.location.address
          this.toolTypeOption = this.tool.toolType.type

          this.setMaxReservationDisplayValue();
          this.setToolInfo();
          this.setUpdateStatusOptions();
          if (this.showToolId === "true") {
            this.toolDisplayName = `#${this.tool.customerToolId} ${this.tool.name}`;
          } else {
            this.toolDisplayName = this.tool.name;
          }
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
      //fetch tooltypes and locations
        let getToolTypesUrl = `/ToolType`
      api.get(getToolTypesUrl)
      .then(response => {
          this.toolTypeOptions = response.data;
          console.log(this.toolTypeOptions);
      })
      .catch(error => {
          console.error('API request error:', error);
      });

      let getLocationsUrl = `/Location?pageSize=100`
      api.get(getLocationsUrl)
      .then(response => {
          this.locationOptions = response.data.result;
      })
      .catch(error => {
          console.error('API request error:', error);
      });

    },
    setMaxReservationDisplayValue(){
      if(this.tool.maxReservationDays && this.tool.maxReservationDays !== 0){
        this.maxReservationDaysDisplayValue = this.tool.maxReservationDays
      }
      else{
        this.maxReservationDaysDisplayValue = '∞'
      }
    },
    setToolInfo()
    {
      if(this.tool.toolReservations.length>0)
      {
        this.employee = this.tool.toolReservations[0].user.firstName + ' ' + this.tool.toolReservations[0].user.lastName
        this.reservedTimestamp = this.tool.toolReservations[0].reservedTimestamp.split('T')[0]
        this.projectName = this.tool.toolReservations[0].project.name
        this.isReserved = true;
      }
      else if(this.tool.externalToolReservations.length>0)
      {
        this.employee = this.tool.externalToolReservations[0].user.firstName + ' ' + this.tool.externalToolReservations[0].user.lastName
        this.reservedTimestamp = this.tool.externalToolReservations[0].reservedTimestamp.split('T')[0]
        this. externalUserLoanRent = this.tool.externalToolReservations[0].externalUser
        if(this.tool.externalToolReservations[0].project){
          this.projectName = this.tool.externalToolReservations[0].project.name
        }
        this.isExternalReserved = true;
      }
      else{
        this.isReserved = false
        this.isExternalReserved = false
        this.employee = ""
        this.reservedTimestamp = ""
        this.projectName = ""
        this. externalUserLoanRent = ""
      }

      if(this.tool.toolToToolGroup && this.tool.toolToToolGroup !== null){
        this.car = this.tool.toolToToolGroup.toolGroup.name
      }


    },
    printQRCode() {
      var tmp = document.createDocumentFragment(),
      printme = document.getElementsByClassName('qr-print-container')[0].cloneNode(true);
      while(document.body.firstChild) {
          // move elements into the temporary space
          tmp.appendChild(document.body.firstChild);
      }
      // put the cloned printable thing back, and print
      document.body.appendChild(printme);
      window.print();

      while(document.body.firstChild) {
          // empty the body again (remove the clone)
          document.body.removeChild(document.body.firstChild);
      }
      // re-add the temporary fragment back into the page, restoring initial state
      document.body.appendChild(tmp);
    },
    handleEdit() {
      //this.enableEdit = true;
      let getToolTypesUrl = `/ToolType`
      api.get(getToolTypesUrl)
      .then(response => {
          this.toolTypeOptions = response.data;
      })
      .catch(error => {
          console.error('API request error:', error);
      });

      let getLocationsUrl = `/Location?pageSize=100`
      api.get(getLocationsUrl)
      .then(response => {
          this.locationOptions = response.data.result;
      })
      .catch(error => {
          console.error('API request error:', error);
      });
      this.enableEdit = true;

    },
    async handleUpdate() {
      if (this.validateInput()) {
        let updateUrl = `/Tool/${this.toolId}`
        if(this.newToolType !== '') {
          await api.post('/ToolType', { type: this.newToolType })
            .then(response => {
              this.tool.toolTypeId = response.data.id;
              this.toolTypeOption = response.data.type;

            })
            .catch(error => {
              console.error('API request error:', error);
              this.$notify({
                message: `${this.$t('common.unableToUpdate')} ${this.tool.name}`,
                icon: "tim-icons icon-alert-circle-exc",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "alert",
                timeout: 2000,
              });
            });
        }
        else {
          this.tool.toolTypeId = this.toolTypeOptions.filter(op => op.type === this.toolTypeOption)[0].id;
        }
        this.tool.locationId = this.locationOptions.filter(op => op.address === this.locationOption)[0].id;

        if(this.updatedStatus !== this.toolStatus)
        {
          this.tool.toolStatusId = this.updateToolStatusOptions.filter(op => op.status === this.updatedStatus)[0].id;
        }

        await api.put(updateUrl, this.tool)
          .then(response => {
            this.purchaseDateGoodFormat = response.data.purchaseDate.split('T')[0];
            this.toolStatus = this.getStatusTranslation(response.data.toolStatusId);
            this.setUpdateStatusOptions()
            this.setMaxReservationDisplayValue();
            this.$notify({
              message: `${this.tool.name} ${this.$t('common.updated')}`,
              icon: "tim-icons icon-check-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
              timeout: 2000,
            });
            this.enableEdit = false;
          })
          .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: `${this.$t('common.unableToUpdate')} ${this.tool.name}`,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 2000,
            });
          });
      }
    },
    validateInput() {
      let success = true;
      if (this.tool.name === '') {
        this.$refs.nameInput.focus();
        this.$notify({
          message: this.$t('errors.nameEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.tool.description === '') {
        this.$refs.descriptionInput.focus();
        this.$notify({
          message: this.$t('errors.descriptionEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.tool.toolImageBytes === '') {
        this.$notify({
          message: this.$t('errors.imageEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.locationOption === '') {
        this.$refs.locationTypeInput.focus();
        this.$notify({
          message: this.$t('errors.locationEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.toolTypeOption === 'New Type' && this.newToolType === '') {
        this.$refs.newToolTypeInput.focus();
        this.$notify({
          message: this.$t('errors.newToolTypeEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.toolTypeOption === '') {
        this.$refs.toolTypeInput.focus();
        this.$notify({
          message: this.$t('errors.toolTypeEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      return success;
    },
    handleCancel() {
      this.enableEdit = false;
      this.tool = this.onCreatedTool;
    },
    handleDelete(){
      swal.fire({
        title: this.$t('delete.areYouSure'),
        text: this.$t('delete.noRevert'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: this.$t('delete.yesDeleteIt'),
        cancelButtonText: this.$t('delete.cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteTool();
        }
      });
    },
    deleteTool() {
        let deleteUrl = `/Tool/${this.toolId}`
        api.delete(deleteUrl)
          .then(response => {
            swal.fire({
              title: this.$t('delete.deleted'),
              text: `${this.$t('delete.youDeleted')} ${this.tool.name}`,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success btn-fill',
              },
              buttonsStyling: false
            });
          this.goToTools();
        })
        .catch(error => {
          console.error('API request error:', error);
          swal.fire({
            title: this.$t('common.somethingWentWrong'),
            text: `${this.$t('common.unableToDelete')} ${this.tool.name}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
            },
            buttonsStyling: false
          });
        });
    },
    handleFileUpload(file) {
      if(file){
        const reader = new FileReader();

        reader.onloadend = () => {
          let binary = '';
          const bytes = new Uint8Array(reader.result);
          bytes.forEach((byte) => binary += String.fromCharCode(byte));
          this.tool.toolImageBytes = btoa(binary);
        };
        reader.readAsArrayBuffer(file);
      }
      else{
        this.tool.toolImageBytes = this.onCreatedTool.toolImageBytes
      }

    },
    goToTools() {
      this.$router.push({ name: 'Tools' });
    },
    getStatusTranslation(statusId)
    {
       switch (statusId) {
            case 1:
              return this.$t('toolStatus.free')
            case 2:
              return this.$t('toolStatus.inUse')
            case 3:
              return this.$t('toolStatus.broken')
            case 4:
              return this.$t('toolStatus.outOfUse')
            case 5:
              return this.$t('toolStatus.loaned')
            case 6:
              return this.$t('toolStatus.rented')
            default:
              return 'N/A'
          }
    },
    calculateDays(date1, date2) {
      var date1_ms = new Date(date1).getTime();
      var date2_ms = new Date(date2).getTime();

      // Calculate the difference in milliseconds
      var difference_ms = Math.abs(date1_ms - date2_ms);

      // Convert back to days and return
      return Math.ceil(difference_ms / (1000 * 60 * 60 * 24));
    },
    updateReservationDays(){
        if (!Number.isInteger(Number(this.updateDays)) || this.updateDays === '') {
          this.$notify({
            message: this.$t('common.needsToBeNumber'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
          return;
        }

        api.put(`/Tool/Reservation/External/${this.latestUnreservedId}`,{days: this.updateDays}).then(response => {
          this.showUpdateReservationDaysModal = false;
          this.updateDays = ''
          swal.fire({
            title: `${this.$t('tools.updatedPrice')} ${response.data.totalPrice} Kr.`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
            },
            buttonsStyling: false
          });
        })
        .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
          });

      },
  }
};
</script>
<style scoped>
.tool-info{
  font-size: 1.0624999875rem;
  color: #1d253b;
}
.qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qr-print-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cancel-new-type {
  padding-left: 10px; /* Adjust as needed */
  padding-top: 10px;  /* Adjust as needed */
}
.new-type {
  padding-left: 30px; /* Adjust as needed */
}
@media print {
  h3 {
    color: black !important; /* Force the text color to be black when printed */
    font-weight: bold !important; /* Force the font weight to be bold when printed */
  }
  .qr-print-container {
    width: 100%;
    height: 50vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .qrcode-vue {
    width: 100%;
    height: auto;
  }
}
</style>
