<template>
  <div class="row-md-12">
    <div class="row mt-5">
      <div class="col-md-12">
        <create-user-form v-if="showCreateUserForm" @close="closeCreateUserForm" @refreshData="fetchData"></create-user-form>
      </div>
      <div class="col-12">
        <card card-body-classes="table-full-width" :title="$t('users.users')" :onAddObject="canCreateUser ? openCreateUserForm : null">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <base-input>
                <el-input
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('common.searchRecords')"
                  v-model="searchQuery"
                  aria-controls="datatables"
                  @keyup.native="search"
                  @clear="handleClearSearch"
                >
                </el-input>
              </base-input>
              <!-- <div v-if="canCreateUser" class="col-sm d-flex justify-content-end">
                <button type="button" v-if="!showCreateUserForm" class="btn btn-primary" @click="openCreateUserForm">Add User</button>
              </div> -->
            </div>
            <el-base-table
              :tableData="tableData"
              :tableColumns="tableColumns"
              :allowClick="true"
              @row-click="goToUser"
            />
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.showing') }} {{ from + 1 }} {{ $t('pagination.to') }} {{ to }} {{ $t('pagination.of') }} {{ total }} {{ $t('pagination.entries') }}
              </p>
            </div>
            <base-pagination
            @input="updatePage"
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import api from "../../services/api"
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import CreateUserForm from './CreateUserForm.vue';
import ElBaseTable from '../../components/ElBaseTable.vue';
import { defaultUserImage } from '../../components/Image Bytes/imagebytes'


export default {
  components: {
    BasePagination,
    CreateUserForm,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    'el-base-table': ElBaseTable
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    }
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      tableColumns: [
        { prop: 'image', label: this.$t('common.image'), width: 80, mobileWidth: 80, showOnMobile: true, isImage: true },
        { prop: 'name', label: this.$t('common.name'), minWidth: 200, showOnMobile: true },
        { prop: 'phoneNumber', label: this.$t('users.phoneNumber'), minWidth: 100 },
        { prop: 'email', label: this.$t('users.email'), minWidth: 250 },
      ],
      tableData: [],
      searchQuery: '',
      showCreateUserForm: false,
      canCreateUser:false,
      initalPage:true
    };
  },
  methods: {
    openCreateUserForm()
    {
      this.showCreateUserForm = true;
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    },
    closeCreateUserForm()
    {
      this.showCreateUserForm = false;
    },
    search(event)
    {
      this.pagination.currentPage = 1;
      const queryLength = this.searchQuery.length;
      if (event.key === 'Backspace' || event.key === 'Enter') {
        this.fetchData();
      }
      else {
        if (queryLength > 1) {
          if (queryLength % 2 === 0) {
            this.fetchData();
          }
        }
      }
    },
    goToUser(user)
    {
      const decodedToken = jwtDecode(Cookies.get('accessToken'));
      if(user.id !== decodedToken.UserIdentifier && this.canCreateUser === false)
      {
        this.$router.push({ name: 'User', params: { userId: user.id } });
      }
      else
      {
        this.$router.push({ name: 'User Profile Page', params: { userId: user.id } });
      }

    },
    updatePage(item){
      this.pagination.currentPage = item;
      this.fetchData()
    },
    fetchData()
    {
        let getUsersUrl = ''
        if(this.searchQuery === '')
        {
            getUsersUrl = `/User?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}`
        }
        else
        {
          getUsersUrl = `/User?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&name=${this.searchQuery}`
        }

        api.get(getUsersUrl)
        .then(response => {
            this.pagination.total = response.data.totalItems;
            this.tableData = response.data.result.map(item => {
            return {
                id: item.id,
                name: item.firstName + ' ' + item.lastName,
                email: item.email,
                phoneNumber: item.phoneNumber,
                image: item.userImageThumbnailBytes !== null ? 'data:image/jpeg;base64,' + item.userImageThumbnailBytes: 'data:image/jpeg;base64,' + defaultUserImage
            };
            });
        })
        .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
        });
    },
    handleClearSearch()
    {
      this.fetchData();
    }
  },
  created() {
    const decodedToken = jwtDecode(Cookies.get('accessToken'));
    let role = decodedToken.role
    if(role === 'Admin' || role === 'SuperAdmin')
    {
      this.canCreateUser = true;
    }
    this.fetchData();
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content{
  font-size: inherit !important;
}
</style>
