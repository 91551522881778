<template>
    <card :title="$t('projects.createProject')" :onCancel="cancel">
      <div class="row">
        <div class="col-md-4">
          <label class="col-md-4 col-form-label">{{$t('projects.key')}} *</label>
          <base-input
            ref="keyInput"
            v-model="key"
            :placeholder="$t('projects.key')"
          >
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label class="col-md-4 col-form-label">{{$t('common.name')}} *</label>
          <base-input
            v-model="name"
            :placeholder="$t('common.name')"
          >
          </base-input>
        </div>
        <div class="col-md-4">
          <label class="col-md-4 col-form-label">{{$t('common.description')}}</label>
  
          <base-input
            v-model="description"
            :placeholder="$t('common.description')"
          >
          </base-input>
        </div>
      </div>
      <div class="row">
  
        <div class="col-sm d-flex justify-content-end">
          <base-button @click="saveProject" type="standard" fill>{{$t('common.submit')}}</base-button>
        </div>
      </div>
    </card>
  </template>
  <script>
  import api from "../../services/api"
  import {Select, Option } from 'element-ui';
  
  
  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
    },
    computed: {
      selectClass() {
        let darkMode = localStorage.getItem('darkMode');
        // If darkMode does not exist in localStorage, default it to 'false'
        if (darkMode === null) {
          darkMode = 'false';
        }
        if (darkMode === 'false') {
          return 'select-standard';
        } else {
          return 'select-primary';
        }
      }
    },
    data() {
      return {
        options: [],
        selectedOption: '',
        description: '',
        name: '',
        key: '',
      };
    },
    methods: {
      cancel()
      {
        this.$emit('close');
      },
      async saveProject() {
        if (this.validateInput()) {
          if(this.name === ''){
            this.name = null
          }
          if(this.description === ''){
            this.description = null
          }
  
          const response = await api.post('/Project', {
            key: this.key,
            name: this.name,
            description: this.description,
            }).then(request => {
                this.$notify({
                  message: "Project created",
                  icon: "tim-icons icon-check-2",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "success",
                  timeout: 1500,
                });
  
                this.$emit('refreshData');
                this.$emit('close');
            }).catch(error => {
              console.error(error)
              this.$notify({
                message: `Unable to create project: ${error.message}`,
                icon: "tim-icons icon-alert-circle-exc",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
                timeout: 0,
              });
          });
        }
      },
      validateInput() {
        let success = true;
        if (this.key === '') {
          this.$refs.keyInput.focus();
          this.$notify({
            message: this.$t('errors.keyEmpty'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "info",
            timeout: 2500,
          });
          success = false;
        }

        if (this.name === '') {
          this.$refs.keyInput.focus();
          this.$notify({
            message: this.$t('errors.nameEmpty'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "info",
            timeout: 2500,
          });
          success = false;
        }
        return success;
      }
    }
  };
  </script>
  <style></style>
  