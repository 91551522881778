<template>
  <div class="row">
    <div class="col-12">
      <div class="col-md-12">
        <create-tool-form v-if="showForm" @close="closeForm" @refreshData="fetchData"></create-tool-form>
      </div>
        <card card-body-classes="table-full-width" :title="$t('common.tool')" :onAddObject="this.userRole !== 'User' ? openForm : null">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <base-input :class="{ 'search-mobile': isMobile }">
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('common.searchRecords')"
                  v-model="searchQuery"
                  aria-controls="datatables"
                  @keyup.native="search"
                  @clear="handleClearSearch"
                >
                </el-input>
              </base-input>
              <div class="col-9 status-select" >
                <select v-if="isMobile" v-model="statusFilter" class="form-control" :placeholder="$t('common.status')" @change="fetchData">
                  <option
                    v-for="status in statusOptions"
                    :key="status.id"
                    :label="status.status"
                    :value="status.status"
                  >
                  </option>
                </select>
              </div>
              <div class="col-9 status-select" >
                <select v-if="isMobile" v-model="toolTypeFilter" class="form-control" :placeholder="$t('common.type')" @change="fetchData">
                  <option
                    v-for="toolType in toolTypeOptions"
                    :key="toolType.id"
                    :label="toolType.type"
                    :value="toolType.type"
                  >
                  </option>
                </select>
              </div>

            </div>
            <!-- Desktop Table -->
            <div v-if="toolTypeOptionsAvailable">
              <el-table v-if="!isMobile" :data="tools" @row-click="goToTool">
                <el-table-column
                  prop="image"
                  :label="$t('common.image')"
                  width="80"
                >
                  <template slot-scope="scope">
                    <img :src="scope.row.image" alt="Tool Image" />
                    </template>
                </el-table-column>
                <el-table-column
                  class="mobile-stack"
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"

                >
                    <template slot="header" >
                      <div v-if="column.label === $t('common.status')">
                        <el-select v-model="statusFilter" :class="selectClass" :placeholder="$t('common.status')" @change="fetchData">
                          <el-option
                            :class="selectClass"
                            v-for="status in statusOptions"
                            :key="status.id"
                            :label="status.status"
                            :value="status.status"

                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div v-else-if="column.label === $t('common.type')">
                        <el-select v-model="toolTypeFilter" :class="selectClass" :placeholder="$t('common.type')" @change="fetchData">
                          <el-option
                            :class="selectClass"
                            v-for="toolType in toolTypeOptions"
                            :key="toolType.id"
                            :label="toolType.type"
                            :value="toolType.type"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div v-else>
                        {{ column.label }}
                      </div>
                    </template>
                </el-table-column>
                <el-table-column v-if="this.userRole !== 'User'" :min-width="100" align="right" label="">
                  <div slot-scope="props">
                    <base-button
                      @click.native.stop="handleEdit(props.$index, props.row)"
                      class="edit btn-link"
                      type="warning"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                    <base-button
                      @click.native.stop="handleDelete(props.$index, props.row)"
                      class="remove btn-link"
                      type="danger"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </div>
                </el-table-column>
              </el-table>

              <!-- Mobile Table -->
              <el-table v-else :data="tools" @row-click="goToTool">
                <el-table-column :label="$t('common.image')">
                  <template slot-scope="scope">
                    <img :src="scope.row.image" alt="Tool Image" style="width: 250%; height: auto;" />
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.info')" min-width="150">
                  <template slot-scope="scope">
                    <div>
                      <div><strong>{{$t('common.name')}}:</strong> {{ scope.row.name }}</div>
                      <div><strong>{{$t('tools.brand')}}:</strong> {{ scope.row.brand }}</div>
                      <div><strong>{{$t('common.type')}}:</strong> {{ scope.row.toolTypeId }}</div>
                      <div><strong>{{$t('common.status')}}:</strong> {{ scope.row.status }}</div>
                      <div v-if="userRole !== 'User'">
                        <base-button
                          @click.native.stop="handleEdit(scope.$index, scope.row)"
                          class="edit btn-link"
                          type="warning"
                          size="sm"
                          icon
                        >
                          <i class="tim-icons icon-pencil"></i>
                        </base-button>
                        <base-button
                          @click.native.stop="handleDelete(scope.$index, scope.row)"
                          class="remove btn-link"
                          type="danger"
                          size="sm"
                          icon
                        >
                          <i class="tim-icons icon-simple-remove"></i>
                        </base-button>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.showing') }} {{ from + 1 }} {{ $t('pagination.to') }} {{ to }} {{ $t('pagination.of') }} {{ total }} {{ $t('pagination.entries') }}
              </p>
            </div>
            <base-pagination
            @input="updatePage"
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
    </div>
  </div>
</template>
<script>
import api from "../../services/api"
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import CreateToolForm from "./CreateToolForm.vue";
//import statusMap from "../../components/StatusMap.vue";
import { BasePagination } from 'src/components';
import { Table, TableColumn, Select, Option } from 'element-ui';
import swal from 'sweetalert2';



export default {
  components: {
    CreateToolForm,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
    selectClass() {
      let darkMode = localStorage.getItem('darkMode');
      // If darkMode does not exist in localStorage, default it to 'false'
      if (darkMode === null) {
        darkMode = 'false';
      }
      if (darkMode === 'false') {
        return 'select-standard';
      } else {
        return 'select-primary';
      }
    }
  },
  data() {
    return {
      isMobile: false,
      initalPage: true,
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      tableColumns: [

        {
          prop: 'name',
          label: this.$t('common.name'),
          minWidth: 100
        },
        {
          prop: 'description',
          label: this.$t('common.description'),
          minWidth: 200
        },
        {
          prop: 'brand',
          label: this.$t('tools.brand'),
          minWidth: 100
        },
        {
          prop: 'toolTypeId',
          label: this.$t('common.type'),
          minWidth: 100
        },
        {
          prop: 'status',
          label: this.$t('common.status'),
          minWidth: 100
        }
      ],
      searchQuery: '',
      userRole: null,
      tools:[],
      showForm: false,
      statusFilter:'',
      showToolId: false,
      statusOptions:[
        {id: 0, status: this.$t('toolStatus.all') },
        {id: 1, status: this.$t('toolStatus.free') },
        {id: 2, status: this.$t('toolStatus.inUse') },
        {id: 3, status: this.$t('toolStatus.broken') },
        {id: 4, status: this.$t('toolStatus.outOfUse') },
        {id: 5, status: this.$t('toolStatus.loaned') },
        {id: 6, status: this.$t('toolStatus.rented') }
      ],
      toolTypeFilter: '',
      toolTypeOptions: [{id: 0, type: this.$t('tools.toolTypeStatus') }],
      toolTypeOptionsAvailable: false
    };
  },
  methods: {
    handleClearSearch()
    {
      this.fetchData();
    },
    search(event){
      const queryLength = this.searchQuery.length;
      this.pagination.currentPage = 1;

      if (event.key === 'Backspace' || event.key === 'Enter' || Number.isInteger(Number(this.searchQuery))) {
        this.fetchData();
      }
      else {
        if (queryLength > 1) {
          if (queryLength % 2 === 0) {
            this.fetchData();
          }
        }
      }
    },
    openForm()
    {
      this.showForm = true;
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    },
    checkMobile() {
      this.isMobile = window.innerWidth < 768;
    },
    closeForm()
    {
      this.showForm = false;
    },
    goToTool(item) {
      this.$router.push({ name: 'Tool', params: { toolId: item.id} });
    },
    updatePage(item){
      this.pagination.currentPage = item;
      this.fetchData()
    },
    getStatusTranslation(statusId)
    {
       switch (statusId) {
            case 1:
              return this.$t('toolStatus.free')
            case 2:
              return this.$t('toolStatus.inUse')
            case 3:
              return this.$t('toolStatus.broken')
            case 4:
              return this.$t('toolStatus.outOfUse')
            case 5:
              return this.$t('toolStatus.loaned')
            case 6:
              return this.$t('toolStatus.rented')
            default:
              return 'N/A'
          }
    },
    deleteTool(item) {
      let deleteUrl = `/Tool/${item.id}`
      api.delete(deleteUrl)
        .then(response => {
          swal.fire({
            title: this.$t('delete.deleted'),
            text: `${this.$t('delete.youDeleted')} ${item.name}`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-fill'
            },
            buttonsStyling: false
          });
        this.tools = this.tools.filter(i => i.id != item.id);
      })
      .catch(error => {
        console.error('API request error:', error);
          this.$notify({
            message: `${this.$t('common.unableToDelete')} ${item.name}`,
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
        });
      });
    },
    handleEdit(index, row)
    {
      this.$router.push({ name: 'Tool', params: { toolId: row.id, editMode:true} });
    },
    handleDelete(index, row) {

      swal.fire({
        title: this.$t('delete.areYouSure'),
        text: this.$t('delete.noRevert'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: this.$t('delete.yesDeleteIt'),
        cancelButtonText: this.$t('delete.cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteTool(row);
        }
      });
    },
    fetchData() {
      let statusUrlBit = ''

      if(this.statusFilter !== this.$t('toolStatus.all') && this.statusFilter !== '')
      {
        let id = this.statusOptions.filter(op => op.status === this.statusFilter)[0].id;
        statusUrlBit = `&toolStatusId=${id}`
      }

      let toolTypeUrlBit = ''

      if(this.toolTypeFilter !== this.$t('tools.toolTypeStatus') && this.toolTypeFilter !== '')
      {
        let id = this.toolTypeOptions.filter(op => op.type === this.toolTypeFilter)[0].id;
        toolTypeUrlBit = `&toolTypeId=${id}`
      }

      let toolsAvailableUrl = ''
      if(this.searchQuery === '')
      {
        toolsAvailableUrl = `/Tool?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}` + statusUrlBit + toolTypeUrlBit
      }
      else
      {
        toolsAvailableUrl = `/Tool?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&searchString=${this.searchQuery}`+ statusUrlBit + toolTypeUrlBit
      }
      api.get(toolsAvailableUrl)
        .then(response => {
          this.pagination.total = response.data.totalItems;
          this.tools = response.data.result.map(item => {
            let toolName = item.name;
            if (this.showToolId) {
              toolName = `#${item.customerToolId} ${toolName}`;
            }
            return {
              id: item.id,
              locationId: item.locationId,
              toolTypeId: this.toolTypeOptions.filter(op => op.id === item.toolTypeId)[0].type,
              image: 'data:image/jpeg;base64,' + item.toolImageThumbnailBytes,
              status: this.getStatusTranslation(item.toolStatusId),
              name: toolName,
              description: item.description,
              sku: item.sku,
              dailyPrice: item.dailyPrice,
              brand: item.brandName,
              purchased: new Date(item.purchaseDate).toLocaleDateString('is-IS')
            };
        });
      })
      .catch(error => {
        console.error('API request error:', error);
        this.$notify({
          message: `Unable to retrieve tool data: ${error.message} <br> Please refresh the page`,
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      });
    },
    fetchToolTypesData() {
      let getToolTypesUrl = `/ToolType`
      api.get(getToolTypesUrl)
      .then(response => {
        this.toolTypeOptions.push(...response.data);
        this.fetchData();
        this.toolTypeOptionsAvailable = true;
      })
      .catch(error => {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  beforeMount() {
    this.checkMobile();
  },
  created() {
    window.addEventListener('resize', this.checkMobile);
    const token = Cookies.get('accessToken');
    const decodedToken = jwtDecode(token);
    this.userRole = decodedToken.role

    if(window.innerWidth < 768){
      this.statusFilter = this.$t('toolStatus.all');
      this.toolTypeFilter = this.$t('tools.toolTypeStatus');
    }

    // Check if the cookie exists
    if (!Cookies.get('showToolId')) {
      // Replace with your API endpoint
      let url = "Customers/CustomerSettings?key=ShowToolId";
      api.get(url)
        .then(response => {
          if (response.data.length > 0) {
            // Store the value in the cookie
            Cookies.set('showToolId', response.data[0].value === 'true');
            // Set the component data property
            this.showToolId = response.data[0].value === 'true';
          }
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        });
    } else {
      // If the cookie exists, set the component data property
      this.showToolId = Cookies.get('showToolId') === 'true';
    }
    this.fetchToolTypesData();
  },
};
</script>
<style scoped>
  .mobile-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .mobile-image {
    flex: 1;
  }
  .mobile-data {
    flex: 3;
    display: flex;
    flex-direction: column;
  }
  .status-select{
    padding-bottom: 20px;
  }
  .search-mobile{
    margin-bottom: 0;
  }

</style>

