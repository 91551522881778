<template>
<div>
  <div class="row">
    <div class="col-md-6 position-relative">
        <h1>{{ project.name }}</h1>
        <h2>{{ project.key }}</h2>
        <h3 v-if="userRole !== 'User'">{{ GetStatusTranslation(project.projectStatusId) }}</h3>
    </div>
  </div>
  <!-- <ScannerInputForm v-if="!isMobile" :reservedTools="currentlyReservedTable.data" :inverseCheck="true" :checkReserved="true" :onSubmit="reserve"></ScannerInputForm> -->
  <div class="row">
    <div class="col-12">
      <card :title="cardTitle">
        <!-- <template v-slot:header-extra v-if="isMobile">
          <div class="reserve-tools">
            <QrScanner class="qr-scanner-reserve" @decoded="callApi" />
          </div>
        </template> -->
        <template v-slot:table-select>
          <div>
            <div
              class="btn-group btn-group-toggle"
              data-toggle="buttons"
            >
              <label
                v-for="(option, index) in myTablesCategories"
                :key="option.name"
                class="btn btn-sm btn-simple"
                :class="[{ active: activeIndex === index}, selectClass]"
                :id="index"
              >
                <input
                  type="radio"
                  @click="initTable(index)"
                  name="options"
                  autocomplete="off"
                  :checked="activeIndex === index"
                />
                <span class="d-none d-sm-block" :style="{ color: activeIndex === index ? 'white' : ''}">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                  <i :class="option.icon" :style="{ color: activeIndex === index ? 'white' : ''}"></i>
                </span>
              </label>
            </div>
          </div>
        </template>
        <div v-if="tableDataReady">
          <div class="table-responsive">
            <el-base-table
              :tableData="currentlyReservedTable.data"
              :tableColumns="currentlyReservedTable.columns"
              :allowClick="true"
              @row-click="goToTool"
            />
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.showing') }} {{ reserved_from + 1 }} {{ $t('pagination.to') }} {{ reserved_to }} {{ $t('pagination.of') }} {{ reserved_total }} {{ $t('pagination.entries') }}
              </p>
            </div>
            <base-pagination
              @input="updateReservedPage"
              class="pagination-no-border"
              v-model="reservedPagination.currentPage"
              :per-page="reservedPagination.perPage"
              :total="reserved_total"
            >
            </base-pagination>
          </div>
        </div>
        <div v-if="userRole !== 'User' && historyTableDataReady">
          <div class="table-responsive">
            <el-base-table
              :tableData="historyTable.data"
              :tableColumns="historyTable.columns"
              :allowClick="true"
              @row-click="goToTool"
            />
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.showing') }} {{ history_from + 1 }} {{ $t('pagination.to') }} {{ history_to }} {{ $t('pagination.of') }} {{ history_total }} {{ $t('pagination.entries') }}
              </p>
            </div>
            <base-pagination
              @input="updateHistoryPage"
              class="pagination-no-border"
              v-model="historyPagination.currentPage"
              :per-page="historyPagination.perPage"
              :total="history_total"
            >
            </base-pagination>
          </div>
        </div>
        <div v-if="currentlyLoanedRentedTableDataReady">
          <div class="table-responsive">
            <el-base-table
              :tableData="currentlyLoanedRentedTable.data"
              :tableColumns="currentlyLoanedRentedTable.columns"
              :allowClick="true"
              @row-click="goToTool"
            />
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.showing') }} {{ loanRent_from + 1 }} {{ $t('pagination.to') }} {{ loanRent_to }} {{ $t('pagination.of') }} {{ loanRent_total }} {{ $t('pagination.entries') }}
              </p>
            </div>
            <base-pagination
              @input="updateLoanRentPage"
              class="pagination-no-border"
              v-model="loanRentPagination.currentPage"
              :per-page="loanRentPagination.perPage"
              :total="loanRent_total"
            >
            </base-pagination>
          </div>
        </div>
        <div v-if="userRole !== 'User' && historyLoanedRentedTableDataReady" >
          <div class="table-responsive">
            <el-base-table
              :tableData="historyLoanedRentedTable.data"
              :tableColumns="historyLoanedRentedTable.columns"
              :allowClick="true"
              @row-click="goToTool"
            />
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.showing') }} {{ loanRent_history_from + 1 }} {{ $t('pagination.to') }} {{ loanRent_history_to }} {{ $t('pagination.of') }} {{ loanRent_history_total }} {{ $t('pagination.entries') }}
              </p>
            </div>
            <base-pagination
              @input="updateLoanRentHistoryPage"
              class="pagination-no-border"
              v-model="loanRentHistoryPagination.currentPage"
              :per-page="loanRentHistoryPagination.perPage"
              :total="loanRent_history_total"
            >
            </base-pagination>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import api from "../../services/api";  // Import your API service
import ElBaseTable from "../../components/ElBaseTable.vue";
import { BasePagination } from 'src/components';


export default {
  computed: {
    reserved_to() {
      let highBound = this.reserved_from + this.reservedPagination.perPage;
      if (this.reserved_total < highBound) {
        highBound = this.reserved_total;
      }
      return highBound;
    },
    reserved_from() {
      return this.reservedPagination.perPage * (this.reservedPagination.currentPage - 1);
    },
    reserved_total() {
      return this.reservedPagination.total;
    },

    history_to() {
      let highBound = this.history_from + this.historyPagination.perPage;
      if (this.history_total < highBound) {
        highBound = this.history_total;
      }
      return highBound;
    },
    history_from() {
      return this.historyPagination.perPage * (this.historyPagination.currentPage - 1);
    },
    history_total() {
      return this.historyPagination.total;
    },

    loanRent_to() {
      let highBound = this.loanRent_from + this.loanRentPagination.perPage;
      if (this.loanRent_total < highBound) {
        highBound = this.loanRent_total;
      }
      return highBound;
    },
    loanRent_from() {
      return this.loanRentPagination.perPage * (this.loanRentPagination.currentPage - 1);
    },
    loanRent_total() {
      return this.loanRentPagination.total;
    },

    loanRent_history_to() {
      let highBound = this.loanRent_history_from + this.loanRentHistoryPagination.perPage;
      if (this.loanRent_history_total < highBound) {
        highBound = this.loanRent_history_total;
      }
      return highBound;
    },
    loanRent_history_from() {
      return this.loanRentHistoryPagination.perPage * (this.loanRentHistoryPagination.currentPage - 1);
    },
    loanRent_history_total() {
      return this.loanRentHistoryPagination.total;
    },
    myTablesCategories() {
      return this.userRole !== 'User' ?
        [{ name: this.$t('projects.toolsAssignedToProject'), icon: 'tim-icons icon-settings' }, { name: this.$t('projects.reservationHistory'), icon: 'tim-icons icon-notes' }, { name: this.$t('projects.toolsLoanedRentedOnProject'), icon: 'tim-icons icon-user-run' }, { name: this.$t('projects.loanRentHistory'), icon: 'tim-icons icon-paper' }]
        : [{ name: this.$t('projects.toolsAssignedToProject'), icon: 'tim-icons icon-settings' }, { name: this.$t('projects.toolsLoanedRentedOnProject'), icon: 'tim-icons icon-user-run' }, { name: this.$t('projects.loanRentHistory'), icon: 'tim-icons icon-paper' }];
    },
    selectClass() {
      let darkMode = localStorage.getItem('darkMode');
      // If darkMode does not exist in localStorage, default it to 'false'
      if (darkMode === null) {
        darkMode = 'false';
      }
      if (darkMode === 'false') {
        return 'btn-standard';
      } else {
        return 'btn-primary';
      }
    }
  },
  data() {
    return {
      activeIndex: 0,
      reservedPagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      historyPagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      loanRentPagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      loanRentHistoryPagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      projectId: "",
      project: {}, // This will hold the project data
      tableDataReady: false,
      currentlyReservedTable: {},
      currentlyLoanedRentedTable: {},
      currentlyLoanedRentedTableDataReady: false,
      historyLoanedRentedTable: {},
      historyLoanedRentedTableDataReady: false,
      historyTable: undefined,
      historyTableDataReady: false,
      inputs: [''],
      invalidUuids: [], // This will hold the invalid UUIDs
      isMobile: window.innerWidth <= 768,
      userRole: "",
      initalReservedPage: true,
      initalHistoryPage: true,
      initialLoanRentPage: true,
      initialLoanRentHistoryPage: true,
      cardTitle: this.$t('projects.toolsAssignedToProject'),
      showToolId: Cookies.get('showToolId')
    };
  },
  components: {
    BasePagination,
    'el-base-table': ElBaseTable
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateIsMobile);
  },
  created() {
    window.addEventListener('resize', this.updateIsMobile);
    this.projectId = this.$route.params.projectId
    let projectUrl = `/Project/${this.projectId}`;
    api.get(projectUrl)
      .then(response => {
        this.project = response.data;
      })
      .catch(error => {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      });
    const token = Cookies.get('accessToken');
    const decodedToken = jwtDecode(token);
    this.userRole = decodedToken.role;
    this.fetchCurrentlyReservedTableData();  // Fetch the table data
  },
  methods: {
    initTable(index) {
      this.activeIndex = index;
      this.currentlyLoanedRentedTableDataReady = false;
      this.tableDataReady = false;
      this.historyTableDataReady = false;
      this.historyLoanedRentedTableDataReady = false;
      if (this.userRole !== 'User') {
        if (this.activeIndex === 0) {
          this.cardTitle = this.$t('projects.toolsAssignedToProject');
          this.fetchCurrentlyReservedTableData();
        }
        else if (this.activeIndex === 1) {
          this.cardTitle = this.$t('projects.reservationHistory');
          this.fetchHistoryTableData();
        }
        else if (this.activeIndex === 2) {
          this.cardTitle = this.$t('projects.toolsLoanedRentedOnProject')
          this.fetchCurrentlyLoanedRentedTableData();
        }
        else {
          this.cardTitle = this.$t('projects.loanRentHistory');
          this.fetchLoanRentHistoryTableData();
        }
      }
      else {
        if (this.activeIndex === 0) {
          this.cardTitle = this.$t('projects.toolsAssignedToProject');
          this.fetchCurrentlyReservedTableData();
        }
        else if (this.activeIndex === 1) {
          this.cardTitle = this.$t('projects.toolsLoanedRentedOnProject')
          this.fetchCurrentlyLoanedRentedTableData();
        }
        else {
          this.cardTitle = this.$t('projects.loanRentHistory');
          this.fetchLoanRentHistoryTableData();
        }
      }

    },
    updateReservedPage(item){
      this.reservedPagination.currentPage = item;
      if(this.initalReservedPage){
        this.initalReservedPage = false;
      }
      else{
        this.fetchCurrentlyReservedTableData()
      }
    },
    updateLoanRentPage(item){
      this.loanRentPagination.currentPage = item;
      this.fetchCurrentlyLoanedRentedTableData()
    },
    updateLoanRentHistoryPage(item){
      this.loanRentHistoryPagination.currentPage = item;
      this.fetchLoanRentHistoryTableData()

    },
    updateHistoryPage(item){
      this.historyPagination.currentPage = item;
      this.fetchHistoryTableData()
    },
    async fetchLoanRentHistoryTableData(){
      if(this.userRole === 'User') {
        return;
      }

      this.historyLoanedRentedTable = {
        title: this.$t('projects.loanRentHistory'),
        columns: [
          { prop: 'image', label: this.$t('common.image'), width: 80, mobileWidth: 100, showOnMobile: true, isImage: true },
          { prop: 'tool', label: this.$t('common.tool'), minWidth: 150 },
          { prop: 'loanedRented', label: this.$t('tools.loanedRented'), minWidth: 100  },
          { prop: 'externalUser', label: this.$t('tools.loanedRentedTo'), minWidth: 90 },
          { prop: 'reserved', label: this.$t('tools.reserved'), minWidth: 80 },
          { prop: 'returned', label: this.$t('tools.returned'), minWidth: 100 },
          { prop: 'loanedRentedBy', label: this.$t('tools.loanedRentedBy'), minWidth: 170 },
          { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.tool': 'tool', 'tools.loanedRented': 'loanedRented', 'tools.loanedRentedTo': 'externalUser', 'tools.loanedRentedDate': 'reserved', 'tools.returned':'returned', 'tools.loanedRentedBy': 'loanedRentedBy'}},
        ],
        data: [],
      }
      let toolReserveUrl = `/Tool/Reservations/External?currentPage=${this.loanRentHistoryPagination.currentPage}&pageSize=${this.loanRentHistoryPagination.perPage}&projectId=${this.projectId}&unreserved=true`;
      try {
        const response = await api.get(toolReserveUrl);
        this.loanRentHistoryPagination.total = response.data.totalItems;
        this.historyLoanedRentedTable.data = response.data.result.map(item => {
          let loanRent = this.$t('tools.loaned');
          if(item.rented)
          {
            loanRent = this.$t('tools.rented');
          }

          return {
            toolId: item.toolId,
            image: 'data:image/jpeg;base64,' + item.tool.toolImageThumbnailBytes, //should be image
            tool: this.showToolId === "true" ? `#${item.tool.customerToolId} ${item.tool.name}` : item.tool.name, // should be toolname
            reserved: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            returned: new Date(item.unreservedTimestamp).toLocaleDateString('is-IS'),
            externalUser: item.externalUser,
            loanedRented:loanRent,
            loanedRentedBy: this.userRole === 'User' ? undefined : item.user.firstName + " " + item.user.lastName //should be username
          };
        });
        this.historyLoanedRentedTableDataReady = true;
      }
      catch (error) {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      }

    },

    async fetchHistoryTableData(){
      if (this.userRole === 'User') {
        return;
      }
      this.historyTable = {
        title: this.$t('projects.reservationHistory'),
        columns: [
          { prop: 'image', label: this.$t('common.image'), width: 80, mobileWidth: 100, showOnMobile: true, isImage: true },
          { prop: 'tool', label: this.$t('common.tool'), minWidth: 200 },
          { prop: 'reserved', label: this.$t('tools.reserved'), minWidth: 100 },
          { prop: 'returned', label: this.$t('tools.returned'), minWidth: 100 },
          { prop: 'reserved by', label: this.$t('tools.reservedBy'), minWidth: 200},
          { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.tool': 'tool', 'tools.reservedBy': 'reserved by', 'tools.reserved': 'reserved','tools.returned': 'returned'}},
        ],
        data: [],
      }
      let toolReserveUrl = `/Tool/Reservations?currentPage=${this.historyPagination.currentPage}&pageSize=${this.historyPagination.perPage}&projectId=${this.projectId}&unreserved=true`;
      try {
        const response = await api.get(toolReserveUrl);
        this.historyPagination.total = response.data.totalItems;
        this.historyTable.data = response.data.result.map(item => {
          return {
            toolId: item.toolId,
            image: 'data:image/jpeg;base64,' + item.tool.toolImageThumbnailBytes, //should be image
            tool: this.showToolId === "true" ? `#${item.tool.customerToolId} ${item.tool.name}` : item.tool.name,
            'reserved': new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            'returned': new Date(item.unreservedTimestamp).toLocaleDateString('is-IS'),
            'reserved by': this.userRole === 'User' ? undefined : item.user.firstName + " " + item.user.lastName //should be username
          };
        });
        this.historyTableDataReady = true;
      }
      catch (error) {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      }
    },

    async fetchCurrentlyLoanedRentedTableData(){
      let toolReserveUrl = `/Tool/Reservations/External?currentPage=${this.loanRentPagination.currentPage}&pageSize=${this.loanRentPagination.perPage}&projectId=${this.projectId}`;
      if (this.userRole === 'User') {
        toolReserveUrl += "&forCurrentUser=true";
        this.currentlyLoanedRentedTable = {
          title: this.$t('projects.toolsLoanedRentedOnProject'),
          columns: [
            { prop: 'image', label: this.$t('common.image'), width: 80, mobileWidth: 100, showOnMobile: true, isImage: true },
            { prop: 'tool', label: this.$t('common.tool'), minWidth: 150 },
            { prop: 'loanedRented', label: this.$t('tools.loanedRented'), minWidth: 60 },
            { prop: 'externalUser', label: this.$t('tools.loanedRentedTo'), minWidth: 100 },
            { prop: 'reserved', label: this.$t('tools.reserved'), minWidth: 80 },
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.tool': 'tool', 'tools.loanedRented': 'loanedRented', 'tools.loanedRentedTo': 'externalUser', 'tools.loanedRentedDate': 'reserved'}},
          ],
          data: [],
        }
      }
      else {
        this.currentlyLoanedRentedTable = {
          title: this.$t('projects.toolsLoanedRentedOnProject'),
          columns: [
            { prop: 'image', label: this.$t('common.image'), width: 80, mobileWidth: 100, showOnMobile: true, isImage: true },
            { prop: 'tool', label: this.$t('common.tool'), minWidth: 150 },
            { prop: 'loanedRented', label: this.$t('tools.loanedRented'), minWidth: 60 },
            { prop: 'externalUser', label: this.$t('tools.loanedRentedTo'), minWidth: 100 },
            { prop: 'reserved', label: this.$t('tools.loanedRentedDate'), minWidth: 80 },
            { prop: 'loanedRentedBy', label: this.$t('tools.loanedRentedBy'), minWidth: 170 },
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.tool': 'tool', 'tools.loanedRented': 'loanedRented', 'tools.loanedRentedTo': 'externalUser', 'tools.loanedRentedDate': 'reserved', 'tools.loanedRentedBy': 'loanedRentedBy'}},
          ],
          data: [],
        }
      }

      try {
        const response = await api.get(toolReserveUrl);
        this.loanRentPagination.total = response.data.totalItems;
        this.currentlyLoanedRentedTable.data = response.data.result.map(item => {
        let loanRent = this.$t('tools.loaned');
        if(item.rented)
        {
          loanRent = this.$t('tools.rented');
        }
        return {
            toolId: item.toolId,
            image: 'data:image/jpeg;base64,' + item.tool.toolImageThumbnailBytes, //should be image
            tool: this.showToolId === "true" ? `#${item.tool.customerToolId} ${item.tool.name}` : item.tool.name,
            reserved: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            loanedRented: loanRent,
            externalUser: item.externalUser,
            loanedRentedBy: this.userRole === 'User' ? undefined : item.user.firstName + " " + item.user.lastName //should be username
          };
        });
        this.currentlyLoanedRentedTableDataReady = true;
      } catch (error) {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      }
    },

    async fetchCurrentlyReservedTableData() {
      let toolReserveUrl = `/Tool/Reservations?currentPage=${this.reservedPagination.currentPage}&pageSize=${this.reservedPagination.perPage}&projectId=${this.projectId}`;
      if (this.userRole === 'User') {
        toolReserveUrl += "&forCurrentUser=true";
        this.currentlyReservedTable = {
          title: this.$t('projects.toolsAssignedToProject'),
          columns: [
            { prop: 'image', label: this.$t('common.image'), width: 80, mobileWidth: 100, showOnMobile: true, isImage: true },
            { prop: 'tool', label: this.$t('common.tool'), minWidth: 200 },
            { prop: 'days left', label: this.$t('tools.daysLeft'), minWidth: 60 },
            { prop: 'reserved', label: this.$t('tools.reserved'), minWidth: 100 },
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.tool': 'tool', 'tools.daysLeft': 'days left', 'tools.reserved': 'reserved'}},

          ],
          data: [],
        }
      }
      else {
        this.currentlyReservedTable = {
          title: this.$t('projects.toolsAssignedToProject'),
          columns: [
            { prop: 'image', label: this.$t('common.image'), width: 80, mobileWidth: 100, showOnMobile: true, isImage: true },
            { prop: 'tool', label: this.$t('common.tool'), minWidth: 200 },
            { prop: 'days left', label: this.$t('tools.daysLeft'), minWidth: 60 },
            { prop: 'reserved', label: this.$t('tools.reserved'), minWidth: 100 },
            { prop: 'reserved by', label: this.$t('tools.reservedBy'), minWidth: 200},
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.tool': 'tool', 'tools.daysLeft': 'days left', 'tools.reservedBy': 'reserved by', 'tools.reserved': 'reserved'}},
          ],
          data: [],
        }
      }

      try {
        const response = await api.get(toolReserveUrl);
        this.reservedPagination.total = response.data.totalItems;
        this.currentlyReservedTable.data = response.data.result.map(item => {
          let daysLeft = ''
          if(item.tool.maxReservationDays && item.tool.maxReservationDays !== 0){
            const currentDate = new Date();
            const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
            const daysSinceReservation = Math.round((currentDate - new Date(item.reservedTimestamp)) / oneDayInMilliseconds);
            daysLeft = item.tool.maxReservationDays  - daysSinceReservation;
          }
          else{
            daysLeft = '∞'
          }

          return {
            toolId: item.toolId,
            image: 'data:image/jpeg;base64,' + item.tool.toolImageThumbnailBytes, //should be image
            tool: this.showToolId === "true" ? `#${item.tool.customerToolId} ${item.tool.name}` : item.tool.name,
            'reserved': new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            'days left': daysLeft,
            'reserved by': this.userRole === 'User' ? undefined : item.user.firstName + " " + item.user.lastName //should be username
          };
        });
        this.tableDataReady = true;
      } catch (error) {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      }
    },
    reserve(toolIds) {
      toolIds.forEach(toolId => {
        this.callApi(toolId);
      });
    },
    async callApi(qrContent) {
      try {
        if (this.isMobile) {
          qrContent = qrContent.split("/").pop();
        }
        if (this.validateUuid(qrContent)) {
          const response = await api.post('/Tool/Reserve', {
            toolId: qrContent,
            projectId: this.projectId
          });
          this.$notify({
            message: this.$t('tools.toolReserved'),
            icon: "tim-icons icon-check-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 1500,
          });
        } else {
            this.$notify({
              message: this.$t('tools.invalidToolId') + " " + qrContent,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 2000,
            });
          }
      } catch (error) {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('tools.unableToReserveTool'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 2000,
        });
      }
      this.fetchCurrentlyReservedTableData();  // Refresh the table data
    },
    validateUuid(uuid) {
      const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
      return uuidRegex.test(uuid);
    },
    updateIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    goToTool(item) {
      this.$router.push({ name: 'Tool', params: { toolId: item.toolId} });
    },
    GetStatusTranslation(statusId){
      switch(statusId){
        case 1:
          return this.$t('projectStatus.notStarted')
        case 2:
          return this.$t('projectStatus.active')
        case 3:
          return this.$t('projectStatus.onHold')
        case 4:
          return this.$t('projectStatus.finished')
      }
    }
  },
};
</script>
<style scoped>
</style>
