<template>
  <div class="row">
    <div class="col-md-12">
      <card type="plain">
        <h4 slot="header" class="card-title">Yfirlit</h4>
        <div id="stockMap" class="map map-big"></div>
      </card>
    </div>
  </div>
</template>

<script>
import api from "../../services/api"
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const customColors = {
  high: '#6da36d', 
  medium: '#d1a666', 
  low: '#ba5f5f', 
};

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  data() {
    return {
      locations: [],
      map: null
    };
  },
  async created() {
    try {
      await this.fetchLocationData();
    } catch (error) {
      console.error('Error fetching location data:', error);
      this.$notify({
        message: this.$t('common.unableToRetrieveData'),
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "danger",
        timeout: 0,
      });
    }
  },
  methods: {
    async fetchLocationData() {
      //TODO: maxing out at 30 locations currently - lets figure out a better way than this magic number
      //      Maybe only show top 5 locations with most tools? 30 is too much. Should we on pick the thickest cluster?
      let locationWithToolInfoUrl = '/Location?includeToolInfo=true&currentPage=1&pageSize=30';
      try {
        const response = await api.get(locationWithToolInfoUrl);
        const data = await response.data;
        if (data && data.result) {
          this.locations = this.processLocationData(data.result);
          this.initStockMap();
        } else {
          console.error('Unexpected data format from API', data);
        }
      } catch (error) {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      }
    },
    processLocationData(locations) {
      return locations.filter(loc => loc.latitude !== null && loc.longitude !== null)
      .map(loc => ({
        coords: [loc.latitude, loc.longitude],
        stock: loc.toolsFreeAtLocation,
        total: loc.toolsBelongingToLocation,
        inUse: loc.toolsInUseAtLocation,
        broken: loc.toolsBrokenAtLocation,
        outOfUse: loc.toolsOutOfUseAtLocation,
        loan: loc.toolsLoanedAtLocation,
        rent: loc.toolsRentedAtLocation,
        label: `${loc.toolsFreeAtLocation}/${loc.toolsBelongingToLocation}`,
        address: loc.address,
        locationType: loc.locationType.type
      }));
    },
    getPopupContent(loc) {
      return `<b>${loc.address}</b><br>
        ${this.$t('toolStatus.all')}: ${loc.total}<br>
        ${this.$t('toolStatus.free')}: ${loc.stock}<br>
        ${this.$t('toolStatus.inUse')}: ${loc.inUse}<br>
        ${this.$t('toolStatus.broken')}: ${loc.broken}<br>
        ${this.$t('toolStatus.outOfUse')}: ${loc.outOfUse}<br>
        ${this.$t('toolStatus.loaned')}: ${loc.loan}<br>
        ${this.$t('toolStatus.rented')}: ${loc.rent}`;
    },
    initStockMap() {
      if (!this.map) {
        this.map = L.map('stockMap');

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          //attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          maxZoom: 19,
        }).addTo(this.map);

        if (this.locations.length === 1) {
          // If there's only one location, set the view to this location with a fixed zoom level
          this.map.setView(this.locations[0].coords, 14); // 15 is an example zoom level, adjust as needed
        } else if (this.locations.length > 1) {
          // If there are multiple locations, fit the map to their bounds
          const bounds = new L.LatLngBounds(this.locations.map(loc => loc.coords));
          if (bounds.isValid()) {
            this.map.fitBounds(bounds);
          } else {
            console.warn('No valid locations to fit bounds');
            this.map.setView([65.6835, -18.1009], 13); // Default view, as before
          }
        } else {
          // Handle the case where there are no locations
          this.map.setView([65.6835, -18.1009], 13); // Default to some coordinates with a default zoom
        }
      }
      this.locations.forEach(loc => {
        const ratio = loc.stock / loc.total;
        let color = ratio > 0.9 ? customColors.high : (ratio >= 0.5 ? customColors.medium : customColors.low);

        L.circle(loc.coords, {
          color: color,
          radius: 300
        }).addTo(this.map);

        L.marker(loc.coords, { icon: this.createNumberIcon(loc.label) })
          .addTo(this.map)
          .bindPopup(this.getPopupContent(loc));
      });
    },
    createNumberIcon(numberText) {
      return L.divIcon({
        className: 'custom-div-icon',
        html: `<div style="border-radius:50%; width:40px; height:40px; display:flex; justify-content:center; align-items:center; font-weight:bold; color:black;">${numberText}</div>`,
        iconSize: [40, 40],
        iconAnchor: [20, 20]
      });
    }
  }
};
</script>

<style lang="scss">
.card-map {
  .map {
    height: 300px;
    width: 100%;
  }
  .custom-div-icon {
    text-align: center;
  }
}
</style>